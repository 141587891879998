import { useNavigate } from "react-router";
import TopFixedBar from "../../common/listPageLayout/TopFixedBar";

const TopFixedView = ({rowCount, setSearchValue, searchValue}) => {

  return (
    <>
      <TopFixedBar 
        rowCount={rowCount}
        rowCountLabel="Properties"
        searchPlaceholder="Search by Property Name"
        addButtonLabel="Add Property"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        navigateTo="/properties/create" />
    </>
  );
};

export default TopFixedView;
