// import { useEffect, useState } from "react";
// import { useNavigate } from "react-router";
// import RedArrow from "../../../assets/redArrow.png";
// import { AvatarWithLoading } from "../../common/avator";
// import { GetImages } from "../../../utils/GetImages";

// const CardView = (data) => {
// const navigate = useNavigate();

//   return (        
//     <>
//       <div
//         key={data.item.id}
//         className="propertyCardConatiner"
//         onClick={() => navigate("/properties/details/" + data.item.id)}
//       >
//         <div className="propertCardLeft">
//           <div className="Icon60  ">
//             <AvatarWithLoading
//               docNumber={data.item?.mainImageId}
//               avatar={true}
//               Size="Icon60"
//             />
//           </div>
//           <div className="propertyCardDisc">
//             <div className="propertyCardDetails ">
//               <div className="d-flex align-data.items-center ">
//                 <p className=" FW400_LH16_small_text ">
//                   {data.item?.formatted?.PropertyTypeId}
//                 </p>

//                 <div
//                   className="d-flex align-data.items-center  "
//                   style={{
//                     display: data.item.incomeDue === 0 ? "none" : "",
//                   }}
//                 >
//                   <img
//                     src={RedArrow}
//                     className="iconstyle"
//                     style={{
//                       display: data.item.incomeDue === 0 ? "none" : "",
//                     }}
//                   />
//                   <p
//                     className="FW400_LH14_small_text_red"
//                     style={{
//                       display: data.item.incomeDue === 0 ? "none" : "",
//                     }}
//                   >
//                     ${data.item.incomeDue}due
//                   </p>
//                 </div>
//               </div>
//               {/* <div> */}
//               <p className="propertyViewAddress FW500_LH20_sub_text">
//                 {data.item.address.streetAddress}
//               </p>
//               {/* </div> */}
//             </div>
//             <div className="propertyCardQuantity">
//               <div className="propertyCardQuantityDesc">
//                 <img
//                   src={GetImages.OrangeUnit}
//                   className="iconstyle14"
//                 />

//                 <p className="FW400_LH16_small_text">
//                   {data.item.numberOfUnits} Units
//                 </p>
//               </div>
//               <div className="dividerSmallDiv"></div>
//               <div className="propertyCardQuantityDesc">
//                 <img
//                   src={GetImages.OrangeUser}
//                   className="iconstyle14"
//                 />

//                 <p className="FW400_LH16_small_text">
//                   {data.item.vacantUnits} Vacancy
//                 </p>
//               </div>
//               <div className="dividerSmallDiv"></div>
//               <div className="propertyCardQuantityDesc">
//                 <img
//                   src={GetImages.OrangeList}
//                   className="iconstyle14"
//                 />
//                 <p className="FW400_LH16_small_text">
//                   {data.item.openServiceRequests} Request
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="propertCardRight">
//           <div className="d-flex align-data.items-center flex-column justify-content-center">
//             <p className="FW500_LH20_sub_text">
//               ${data.item?.formatted?.Revenue}
//             </p>
//             <p className="FW400_LH16_small_text">Revenue</p>
//           </div>
//           <div className="dividerMediumDiv"></div>
//           <div className="d-flex align-data.items-center flex-column justify-content-center">
//             <p
//               className={`${
//                 data.item?.formatted?.CapRate >= 10
//                   ? `FW500_LH20_FS14_green`
//                   : data.item?.formatted?.CapRate >= 7 &&
//                     data.item?.formatted?.CapRate < 10
//                   ? `FW500_LH20_FS14_yellow`
//                   : `FW500_LH20_FS14_red`
//               }`}
//             >
//               ${data.item?.formatted?.Noi}
//             </p>
//             <p className="FW400_LH16_small_text">NOI</p>
//           </div>
//           <div className="dividerMediumDiv"></div>
//           <div className="d-flex align-data.items-center flex-column justify-content-center">
//             <p
//               className={`${
//                 data.item?.formatted?.CapRate >= 10
//                   ? `FW500_LH20_FS14_green`
//                   : data.item?.formatted?.CapRate >= 7 &&
//                     data.item?.formatted?.CapRate < 10
//                   ? `FW500_LH20_FS14_yellow`
//                   : `FW500_LH20_FS14_red`
//               }`}
//             >
//               {data.item?.formatted?.CapRate}%
//             </p>
//             <p className="FW400_LH16_small_text">Cap Rate</p>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default CardView;


import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import RedArrow from "../../../assets/redArrow.png";
import { AvatarWithLoading } from "../../common/avator";
import { GetImages } from "../../../utils/GetImages";

const CardView = (data) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        key={data.item.id}
        className="propertyCardConatiner"
        onClick={() => navigate("/properties/details/" + data.item.id)}
      >
        <div className="propertCardLeft">
          <div className="Icon60  ">
            <AvatarWithLoading
              docImage={data.item?.mainImageBase64}
              docNumber={data.item?.mainImageId}
              avatar={true}
              Size="Icon60"
            />
          </div>
          <div className="propertyCardDisc">
            <div className="propertyCardDetails ">
              <div className="d-flex align-data.items-center ">
                <p className=" FW400_LH16_small_text ">
                  {data.item?.formatted?.PropertyTypeId}
                </p>

                <div
                  className="d-flex align-data.items-center  "
                  style={{
                    display: data.item.incomeDue === 0 ? "none" : "",
                  }}
                >
                  <img
                    src={RedArrow}
                    className="iconstyle"
                    style={{
                      display: data.item.incomeDue === 0 ? "none" : "",
                    }}
                  />
                  <p
                    className="FW400_LH14_small_text_red"
                    style={{
                      display: data.item.incomeDue === 0 ? "none" : "",
                    }}
                  >
                    ${data.item.incomeDue}due
                  </p>
                </div>
              </div>
              {/* <div> */}
              <p className="propertyViewAddress FW500_LH20_sub_text">
                {data.item.address.streetAddress}
              </p>
              {/* </div> */}
            </div>
            <div className="propertyCardQuantity">
              <div className="propertyCardQuantityDesc">
                <img src={GetImages.OrangeUnit} className="iconstyle14" />

                <p className="FW400_LH16_small_text">
                  {data.item.numberOfUnits} Units
                </p>
              </div>
              <div className="dividerSmallDiv"></div>
              <div className="propertyCardQuantityDesc">
                <img src={GetImages.OrangeUser} className="iconstyle14" />

                <p className="FW400_LH16_small_text">
                  {data.item.vacantUnits} Vacancy
                </p>
              </div>
              <div className="dividerSmallDiv"></div>
              <div className="propertyCardQuantityDesc">
                <img src={GetImages.OrangeList} className="iconstyle14" />
                <p className="FW400_LH16_small_text">
                  {data.item.openServiceRequests} Request
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="propertCardRight">
          <div className="d-flex align-data.items-center flex-column justify-content-center">
            <p className="FW500_LH20_sub_text">
              ${data.item?.formatted?.Revenue}
            </p>
            <p className="FW400_LH16_small_text">Revenue</p>
          </div>
          <div className="dividerMediumDiv"></div>
          <div className="d-flex align-data.items-center flex-column justify-content-center">
            <p
              className={`${
                data.item?.formatted?.CapRate >= 10
                  ? `FW500_LH20_FS14_green`
                  : data.item?.formatted?.CapRate >= 7 &&
                    data.item?.formatted?.CapRate < 10
                  ? `FW500_LH20_FS14_yellow`
                  : `FW500_LH20_FS14_red`
              }`}
            >
              ${data.item?.formatted?.Noi}
            </p>
            <p className="FW400_LH16_small_text">NOI</p>
          </div>
          <div className="dividerMediumDiv"></div>
          <div className="d-flex align-data.items-center flex-column justify-content-center">
            <p
              className={`${
                data.item?.formatted?.CapRate >= 10
                  ? `FW500_LH20_FS14_green`
                  : data.item?.formatted?.CapRate >= 7 &&
                    data.item?.formatted?.CapRate < 10
                  ? `FW500_LH20_FS14_yellow`
                  : `FW500_LH20_FS14_red`
              }`}
            >
              {data.item?.formatted?.CapRate}%
            </p>
            <p className="FW400_LH16_small_text">Cap Rate</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardView;

