import React, { useState, useEffect, useRef } from 'react'
import useWindowDimensions from '../../../hooks/windowDimensionsHook';
import './scrollingContainer.css';

const ScrollingContainer = ({ Content }) => {
  const { height, width } = useWindowDimensions();
  const appBarHeight = 48;
  const [scrollContainerHeight, setScrollContainerHeight] = useState(height - appBarHeight);

  useEffect(() => {
    setScrollContainerHeight(height - appBarHeight);
  }, []);

  return (
    <>
      <div className="scrollingContainer" style={{ height: scrollContainerHeight }}>
        <div className="scrollingContent">
          <Content />
        </div>
      </div>
    </>
  );
};

export default ScrollingContainer;
