import React from "react";
import CapRateComponent from "./CapRateComponent";
import { AnalyticsService } from "@propertelligent/client-api";

const CapRate = ({ data = [], type }) => {
  return (
    <div className="capRateComponentContainer w-100">
      {data.map((item, index) => {
        return (
          <CapRateComponent
            key={`${item.id}_${index}`}
            type={item?.formatted.PropertyType}
            address={item.streetAddress}
            percent={
              type === "Revenue"
                ? item?.formatted.RevenueRaw
                : item?.formatted.ExpensesRaw
            }
            img={item.mainImageBase64}
          />
        );
      })}
      {/* <CapRateComponent
        type="Single Family"
        address="6868 Collins Ave"
        percent="9.8%"
      />
      <CapRateComponent
        type="Multi Family"
        address="1600, Pryor Rd SW"
        percent="9.0%"
      />
      <CapRateComponent
        type="Apartment"
        address="1700, Giben Rd SW"
        percent="8.9%"
      />
      <CapRateComponent
        type="Multi Family"
        address="225, Reformation Pkwy"
        percent="8.5%"
      />
      <CapRateComponent
        type="Apartment"
        address="165, Courtland St NE"
        percent="7.8%"
      /> */}
    </div>
  );
};

export default CapRate;
