import React, { ChangeEvent, useEffect, useState } from "react";
import CustomTenantInput from "./CustomTenantInput";
import { emailRegex, mobileNumberRegex, nameRegex } from "../common/Regex";
import EditCustomComponent from "./EditCustomComponent";
import { TenantService } from "@propertelligent/client-api";
import SuccessAlert from "./SuccessAlert";
import { GetImages } from "../../utils/GetImages";

const EditTenantForm = ({
  id,
  setShowModal,
  setShowSuccessAlert,
  setAlertMessage,
}) => {
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    unitId: null,
    userId: null,
    id: null,
  });

  const [errorMessages, setErrorMessages] = useState({
    firstName: "This field is required",
    lastName: "This field is required",
    email: "This field is required",
    phoneNumber: "This field is required",
  });

  const checkEmptyFields = (): void => {
    if (values.email.trim() === "") {
      setEmailError(true);
    }

    if (values.firstName.trim() === "") {
      setFirstNameError(true);
    }

    if (values.lastName.trim() === "") {
      setLastNameError(true);
    }

    if (values.phoneNumber.trim() === "") {
      setPhoneNumberError(true);
    }
  };

  const submitTimeError = () => {
    return (
      firstNameError ||
      lastNameError ||
      emailError ||
      phoneNumberError ||
      values.firstName.trim() === "" ||
      values.lastName.trim() === "" ||
      values.phoneNumber.trim() === "" ||
      values.email.trim() === ""
    );
  };

  useEffect(() => {
    getSingleTenantData();
  }, []);

  const getSingleTenantData = () => {
    TenantService.getApiTenant1(id)
      .then((res) => {
        setValues({
          ...values,
          firstName: res?.success?.firstName,
          lastName: res?.success?.lastName,
          email: res?.success?.email,
          phoneNumber: res?.success?.phoneNumber,
          unitId: res?.success?.unitId,
          userId: res?.success?.userId,
          id: Number(id),
        });
      })
      .catch((error) => {});
  };

  const validateFirstName = (): boolean => {
    if (values.firstName.length === 0) {
      setFirstNameError(false);
      return false;
    }

    if (values.firstName.trim().length > 0) {
      if (nameRegex.test(values.firstName)) {
        setFirstNameError(false);
      } else if (
        values.firstName.trim().length < 2 ||
        values.firstName.trim().length > 20
      ) {
        setFirstNameError(true);
        setErrorMessages({
          ...errorMessages,
          firstName: "First Name should have 2-20 characters",
        });
      } else {
        setFirstNameError(true);
        setErrorMessages({
          ...errorMessages,
          firstName: "Please enter letters only",
        });
        return true;
      }
    }

    return false;
  };

  const validateLastName = (): boolean => {
    if (values.lastName.length === 0) {
      setLastNameError(false);
      return false;
    }

    if (values.lastName.trim().length > 0) {
      if (nameRegex.test(values.lastName)) {
        setLastNameError(false);
      } else if (
        values.lastName.trim().length < 2 ||
        values.lastName.trim().length > 20
      ) {
        setLastNameError(true);
        setErrorMessages({
          ...errorMessages,
          lastName: "Last Name should have 2-20 characters",
        });
      } else {
        setLastNameError(true);
        setErrorMessages({
          ...errorMessages,
          lastName: "Please enter letters only",
        });
        return true;
      }
    }

    return false;
  };

  const validateNumber = (): boolean => {
    if (values.phoneNumber.trim().length > 0) {
      if (mobileNumberRegex.test(values.phoneNumber)) {
        setPhoneNumberError(false);
      } else {
        setPhoneNumberError(true);
        setErrorMessages({
          ...errorMessages,
          phoneNumber: "Please enter correct phone number",
        });
        return true;
      }
    }

    return false;
  };

  const validateEmail = (): boolean => {
    if (values.email.trim().length > 0) {
      if (emailRegex.test(values.email)) {
        setEmailError(false);
      } else {
        setEmailError(true);
        setErrorMessages({
          ...errorMessages,
          email: "Please enter a valid email",
        });
        return true;
      }
    }
    return false;
  };

  const formDetails = [
    {
      label: "First name",
      placeholder: "Enter first name",
      value: values.firstName,
      name: "firstName",
      blurFunction: validateFirstName,
      errorMessages: errorMessages.firstName,
      isError: firstNameError,
    },
    {
      label: "Last name",
      placeholder: "Enter last name",
      value: values.lastName,
      name: "lastName",
      blurFunction: validateLastName,
      errorMessages: errorMessages.lastName,
      isError: lastNameError,
    },
    {
      label: "Email address",
      placeholder: "Enter email",
      value: values.email,
      name: "email",
      blurFunction: validateEmail,
      errorMessages: errorMessages.email,
      isError: emailError,
    },
    {
      label: "Phone number",
      placeholder: "Enter phone no.",
      value: values.phoneNumber,
      name: "phoneNumber",
      blurFunction: validateNumber,
      errorMessages: errorMessages.phoneNumber,
      isError: phoneNumberError,
    },
  ];

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case "email":
        setEmailError(false);
        setErrorMessages({ ...errorMessages, email: "This field is required" });
        break;
      case "firstName":
        setFirstNameError(false);
        setErrorMessages({
          ...errorMessages,
          firstName: "This field is required",
        });
        break;
      case "lastName":
        setLastNameError(false);
        setErrorMessages({
          ...errorMessages,
          lastName: "This field is required",
        });
        break;
      case "phoneNumber":
        setPhoneNumberError(false);
        setErrorMessages({
          ...errorMessages,
          phoneNumber: "This field is required",
        });
        break;
    }

    setValues({ ...values, [name]: value });
  };

  const editSubmitHandler = (e) => {
    e.preventDefault();

    checkEmptyFields();
    e.preventDefault();

    if (submitTimeError()) {
    } else {
      TenantService.putApiTenant(values)
        .then((res) => {
          setValues({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            unitId: null,
            userId: null,
            id: null,
          });
          setAlertMessage("Tenant updated successfully");
          setShowSuccessAlert(true);
          setShowModal(false);

          setTimeout(() => {
            setShowSuccessAlert(false);
          }, 3000);
        })
        .catch((error) => {});
    }
  };

  return (
    <div>
      <form onSubmit={editSubmitHandler}>
        <div className="editTenantFormContainer">
          {formDetails.map((item) => (
            <EditCustomComponent
              key={item.label}
              label={item.label}
              placeholder={item.placeholder}
              value={item.value}
              name={item.name}
              onChangeFunction={handleChange}
              onBlurFunction={item.blurFunction}
              errorMessages={item.errorMessages}
              isError={item.isError}
            />
          ))}
        </div>

        <div className="editFormFooter">
          <p
            onClick={() => setShowModal(false)}
            className="cancelEdit clickable"
          >
            Cancel
          </p>
          <button type="submit" className="saveEditTenantButton">
            Save Changes <img src={GetImages.WhiteForwardArrow} />
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditTenantForm;
