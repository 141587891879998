import React from "react";

const NameAvatarComponent = ({
  firstName,
  lastName,
  heightWidth,
  fromTopBar = false,
  fontSize = "16px",
  color = "white",
}) => {
  let totalInitials = "";
  const firstLetter = firstName && firstName?.split("")[0]?.toUpperCase();
  const lastLetter = lastName && lastName.length > 0 ? lastName?.split("")[0]?.toUpperCase() : "";

  totalInitials = (firstLetter ? firstLetter : "") + (lastLetter ? lastLetter : "");

  return (
    <div
      style={{
        minHeight: `${heightWidth}px`,
        minWidth: `${heightWidth}px`,
        background: fromTopBar ? "#E1EFFE" : "#3F83F8",
        fontSize: fontSize,
        color: color,
        border: "2.5px solid #C3DDFD",
      }}
      className="initialBox"
    >
      {totalInitials}
    </div>
  );
};

export default NameAvatarComponent;
