import "proxy-polyfill";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../src/components/GlobalCss/globalStyle.css";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { MyProvider } from "./utils/context/LoginContext";
import { PropertyProvider } from "./utils/context/PropertyProvider";
import store from "./store";
import { QuickstartProvider } from "./components/firstTimeUserExperience/Context";
import { OverviewProvider } from "./utils/context/ OverviewContext";

ReactDOM.render(
  <MyProvider>
    <OverviewProvider>
    <Provider store={store}>
      <PropertyProvider>
        <QuickstartProvider>
          <BrowserRouter>
            {/* <HashRouter> */}
            <App />
            {/* </HashRouter> */}
          </BrowserRouter>
        </QuickstartProvider>
      </PropertyProvider>
    </Provider>
    </OverviewProvider>
  </MyProvider>,
  document.getElementById("root")
);
