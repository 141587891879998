import CustomIcon from "../components/common/CustomIcons";
import { GetImages } from "../utils/GetImages";
import Localizer from '../utils/i18nConfig';

export const BASE_API_URL = "https://dev.propertelligent.com";

export const USERS = {
  USER: "USER",
  TENANT: "TENANT",
  CONTRACTOR: "CONTRACTOR",
};

export const USER_ROLES = {
  "1": USERS.USER,
  "4": USERS.TENANT,
  "6": USERS.CONTRACTOR,
};

export const CUSTOM_COLORS = {
  lightGray: "#E5E7EB",
  skyDarkBlue: "#0DBF6F",
  lightGreen: "#1A56DB",
};

export const LOCALE_OPTIONS = {
  EN : 'en',
  EN_GB: 'en-GB',
  EN_US: 'en-US',
  FR: 'fr'
}
export type Language_Type = typeof LOCALE_OPTIONS[keyof typeof LOCALE_OPTIONS]

export const URLS = {
  HOME: "/",
  UNITS: "/units",
  EDIT_UNIT: "/units/edit",
  PROPERTIES: "/properties",
  TENANTS: "/tenants",
  CREATE_TENANT: "/tenants/create",
  SERVICE_REQUESTS: "/servicerequest",
  CONTRACTORS: "/contractors",
  ORGANIZATION: "/organization",
  MESSAGING: "/messaging",
  CALCULATOR: "/calculators",
  INFO: "/info",
  FIRST_TIME_DASHBOARD: "/firsttimedashboard",
  ORGANIZATION_ACCOUNTS: "/organization-accounts",
  PAYMENT_DUE_ACCOUNTS: "/paymentDueAccounts",
  PAYEE_ACCOUNT_MANAGEMENT: "/payeeAccounts",
  USER_DASHBOARD: "/userdashboard",
  QUESTIONNAIRE: "/questionnaire",
  FORGET_PASSWORD: "/forgetpassword",
  CHECK_MAIL: "/checkemail",
  SIGN_UP: "/signup",
  SIGNUP_AFTER_GOOGLE: "/signupaftergoogle",
  CHECK_MAIL_AFTER_SIGNUP: "/checkemailaftersignup",
  RESET_PASSWORD_BY_USERKEY: "/resetpassword/:userKey",
  LOGIN: "/login",
};

export const MAIN_ROUTES_URLS = {
  HOME: `${URLS.HOME}*`,
  UNITS: `/${URLS.UNITS}/*`,
  PROPERTIES: `/${URLS.PROPERTIES}/*`,
  TENANTS: `/${URLS.TENANTS}/*`,
  SERVICE_REQUESTS: `/${URLS.SERVICE_REQUESTS}/*`,
  CONTRACTORS: `/${URLS.CONTRACTORS}/*`,
  ORGANIZATION: `/${URLS.ORGANIZATION}/*`,
  CALCULATOR: `/${URLS.CALCULATOR}/*`,
  MESSAGING: `/${URLS.MESSAGING}/*`,
  INFO: `/${URLS.INFO}/*`,
  ORGANIZATION_ACCOUNTS: `/${URLS.ORGANIZATION_ACCOUNTS}/*`,
  PAYMENT_DUE_ACCOUNTS: `/${URLS.PAYMENT_DUE_ACCOUNTS}/*`,
  PAYEE_ACCOUNT_MANAGEMENT: `/${URLS.PAYEE_ACCOUNT_MANAGEMENT}/*`,
};

export const FORM_CONTROLFIELD_TYPES = {
  NUMBER: "number",
  TEXT: "text",
  EMAIL: "email",
  PASSWORD: "password",
  DATE: "date",
  CURRENCY: "currency",
};

export const generateMenuRoutes = (values, userRoleId: string | number) => {
  switch (userRoleId) {
    //USERCASE
    case 1:
      return [
        {
          id: "1",
          path: URLS.PROPERTIES,
          primaryText: `${Localizer.getLocalizedString('properties_title')} ${values.noOfProperties}`,
          leftIcon: GetImages.propertiesIconMenu,
          selectedIcon: GetImages.selectedPropertiesIconMenu,
        },
        {
          id: "2",
          path: URLS.UNITS,
          primaryText: `${Localizer.getLocalizedString('units_title')} ${values?.noOfUnits}`,
          leftIcon: GetImages.unitIconMenu,
          selectedIcon: GetImages.selectedUnitIconMenu,
        },
        {
          id: "3",
          path: URLS.TENANTS,
          primaryText: `${Localizer.getLocalizedString('tenants_title')} ${values.noOfTenants}`,
          leftIcon: GetImages.tenantIconMenu,
          selectedIcon: GetImages.selectedTenantIconMenu,
        },
        {
          id: "4",
          path: URLS.SERVICE_REQUESTS,
          primaryText: `${Localizer.getLocalizedString('service_requests_title')} ${values.noOfServiceRequests}`,
          leftIcon: GetImages.serviceRequestIconMenu,
          selectedIcon: GetImages.selectedServiceRequestIconMenu,
        },
        {
          id: "5",
          path: URLS.CONTRACTORS,
          primaryText: `${Localizer.getLocalizedString('contractors_title')} ${values.noOfContractors}`,
          leftIcon: GetImages.contractorIconMenu,
          selectedIcon: GetImages.selectedContractorIconMenu,
        },

        {
          id: "11",
          path: URLS.ORGANIZATION_ACCOUNTS,
          primaryText: `${Localizer.getLocalizedString('accounting_title')}`,
          leftIcon: GetImages.accountingIconMenu,
          selectedIcon: GetImages.selectedAccountingIcon,
        },
      ];
    //TENANTCASE
    case 4:
      return [
        {
          id: "1",
          path: URLS.INFO,
          primaryText: "My info",
          leftIcon: (
            <CustomIcon
              src={GetImages.serviceRequestIconMenu}
              alt={"Service Request"}
            />
          ),
        },
        {
          id: "2",
          path: URLS.UNITS,
          primaryText: `${Localizer.getLocalizedString('units_title')} ${values.noOfUnits}`,
          leftIcon: (
            <CustomIcon
              src={GetImages.serviceRequestIconMenu}
              alt={"My Unit"}
            />
          ),
        },
        {
          id: "3",
          path: "/test",
          primaryText: `${Localizer.getLocalizedString('service_requests_title')} ${values.noOfUnits}`,
          leftIcon: (
            <CustomIcon
              src={GetImages.serviceRequestIconMenu}
              alt={"Service Request"}
            />
          ),
        },
        {
          id: "4",
          path: "/test2",
          primaryText: `Payment  ${values.noOfUnits}`,
          leftIcon: (
            <CustomIcon
              src={GetImages.serviceRequestIconMenu}
              alt={"Service Request"}
            />
          ),
        },
        {
          id: "5",
          path: "/test3",
          primaryText: `Messaging  ${values.noOfUnits}`,
          leftIcon: (
            <CustomIcon
              src={GetImages.serviceRequestIconMenu}
              alt={"Service Request"}
            />
          ),
        },
        {
          id: "6",
          path: "/test4",
          primaryText: `COMING SOON Page  ${values.noOfUnits}`,
          leftIcon: (
            <CustomIcon
              src={GetImages.serviceRequestIconMenu}
              alt={"Service Request"}
            />
          ),
        },
      ];
    case 6:
      return [
        {
          id: "1",
          path: URLS.INFO,
          primaryText: "My info",
          leftIcon: (
            <CustomIcon
              src={GetImages.serviceRequestIconMenu}
              alt={"Service Request"}
            />
          ),
        },
        {
          id: "2",
          path: URLS.SERVICE_REQUESTS,
          primaryText: `Service Request  ${values.noOfServiceRequests}`,
          leftIcon: (
            <CustomIcon
              src={GetImages.serviceRequestIconMenu}
              alt={"Service Request"}
            />
          ),
        },
        {
          id: "3",
          path: URLS.PAYMENT_DUE_ACCOUNTS,
          primaryText: "Payment Due Accounts",
          leftIcon: (
            <CustomIcon
              src={GetImages.serviceRequestIconMenu}
              alt={"Service Request"}
            />
          ),
        },
        {
          id: "4",
          path: URLS.PAYEE_ACCOUNT_MANAGEMENT,
          primaryText: "Payee Account Management",
          leftIcon: (
            <CustomIcon
              src={GetImages.serviceRequestIconMenu}
              alt={"Service Request"}
            />
          ),
        },
      ];
    default: {
      return null;
    }
  }
};

export const tenantFields = [
  { label: "First name", placeholder: "Enter First name", name: "firstName" },
  { label: "Last name", placeholder: "Enter Last Name", name: "lastName" },
  {
    label: "Phone Number",
    placeholder: "Enter Phone Number",
    name: "phoneNumber",
  },
  { label: "Email address", placeholder: "Enter email", name: "email" },
];
// Its alternative method is written : DateHelper.convertUTCtoDateTimeSimple(myDateString, showTime);
export const convertDate = (requestDateStr) => {
  const requestDateObj = new Date(requestDateStr);

  const formattedDate = requestDateObj.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  return formattedDate;
};

export const getBathroomText = (number, text) => {
  return number > 1 ? `${text}s` : text;
};

export const defaultPageNo = 1;
export const defaultPageSize = 10; 
     
