import { useState, useEffect, useMemo } from "react";

type Props = {
  httpExpensebycategoryFn?: any;
  httpCashflowFn?: any;
  customMonthField: string;
  parentEntityId: number;
  fullyQualifiedFilters: any;
};

export const useAnalyticsHook = ({
  httpExpensebycategoryFn,
  httpCashflowFn,
  customMonthField,
  parentEntityId,
  fullyQualifiedFilters,
}: Props) => {
  const [cashFlow, setCashFlow] = useState([]);
  const [expensebycategoryArr, setExpensebycategoryArr] = useState([]);
  const [filter, setFilters] = useState();

  const fetchAndFormatExpensebycategory = () => {
    if (typeof httpExpensebycategoryFn === "function" && parentEntityId) {
      httpExpensebycategoryFn(
        parentEntityId,
        undefined,
        undefined,
        undefined,
        fullyQualifiedFilters?.fin
      )
        .then((response: any) => {
          if (response?.success?.length > 0) {
            let formatedExpCatArr = [];
            for (const expCat of response?.success) {
              let found = formatedExpCatArr?.findIndex(
                (t) => t.name === expCat.formatted?.ExpenseType
              );

              if (found > -1) {
                formatedExpCatArr[found] =
                  formatedExpCatArr[found] + Number(expCat.amount);
              } else {
                formatedExpCatArr?.push({
                  name: expCat?.formatted?.ExpenseType,
                  value: expCat?.amount,
                  color: "#" + (((1 << 24) * Math.random()) | 0).toString(16),
                });
              }
            }

            setExpensebycategoryArr(formatedExpCatArr);
          } else {
            setExpensebycategoryArr([]);
          }
        })
        .catch((error: any) => { });
    }
  };

  const fetchAndFormatCashflow = () => {
    if (typeof httpCashflowFn === "function" && parentEntityId) {
      httpCashflowFn(
        parentEntityId,
        undefined,
        undefined,
        undefined,
        fullyQualifiedFilters?.rev
      )
        .then((response: any) => {
          const upd = response?.success?.map(
            (c: { expense: any; income: number; month: any; year: any }) => ({
              expense: c?.expense,
              income: c?.income + 500,
              [customMonthField]: `${c?.month}/${c?.year}`,
            })
          );

          setCashFlow(upd);
        })
        .catch((error: any) => { });
    }
  };
  useMemo(() => {
    fetchAndFormatCashflow();
  }, [fullyQualifiedFilters?.rev]);
  useMemo(() => {
    fetchAndFormatExpensebycategory();
  }, [fullyQualifiedFilters?.fin, expensebycategoryArr?.length]);
  useEffect(() => {
    fetchAndFormatCashflow();
    fetchAndFormatExpensebycategory();
  }, []);

  return {
    cashFlow,
    expensebycategoryArr,
    fetchAndFormatExpensebycategory,
  };
};
