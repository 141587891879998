import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import TenantDetails from "./tenantDetails";
import Transactions from "../properties/components/transactions";
import {
  AnalyticsService,
  PaymentService,
  TenantService,
} from "@propertelligent/client-api";
import PieChart from "../properties/components/expenseDistribution";
import { useAnalyticsHook } from "../../hooks/useAnalyticsHook";

interface OverViewProps {
  id: string;
  tenantId: number | null;
  paymentMethodsArr: any[];
  payeeAccountsArr: any[];
  rows: any[];
  httpFetchDataFn: any;
  tenantData: any;
  loading: boolean;
  expensebycategoryArr: any;
  fetchAndFormatExpensebycategory: any;
  fin_monthValue: any;
  setFin_MonthValue: any;
}

const OverViewUnit: React.FC<OverViewProps> = React.memo(
  ({
    paymentMethodsArr,
    payeeAccountsArr,
    tenantId,
    id,
    rows,
    httpFetchDataFn,
    tenantData,
    loading,
    expensebycategoryArr,
    fetchAndFormatExpensebycategory,
    fin_monthValue,
    setFin_MonthValue,
  }) => {
    // const [showModel, setShowModel] = useState(false);
    // const [rev_monthValue, setRev_MonthValue] = useState(-12);
    // const [fin_monthValue, setFin_MonthValue] = useState(-12);
    // const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    // const customMonthField = "monthYear";

    // const { cashFlow, expensebycategoryArr, fetchAndFormatExpensebycategory } = useAnalyticsHook({
    //   httpExpensebycategoryFn: AnalyticsService.getApiAnalyticsUnitExpensebycategory,
    //   httpCashflowFn: AnalyticsService.getApiAnalyticsUnitCashflow,
    //   customMonthField,
    //   parentEntityId: parseInt(id),
    //   fullyQualifiedFilters: {
    //     rev: `Created>DateTime.UtcNow.AddMonths(${rev_monthValue})`,
    //     fin: `Created>DateTime.UtcNow.AddMonths(${fin_monthValue})`,
    //   },
    // });

    // UseEffect to handle side effects based on rows length change
    useEffect(() => {}, [rows?.length]);

    // No need for empty useMemo as it's not doing anything here
    // useMemo(() => { }, [fin_monthValue, rev_monthValue, expensebycategoryArr]);

    return (
      <Container fluid>
        <Row className="m-0">
          <div>
            {tenantData ? (
              <TenantDetails
                tenantId={tenantId}
                tenantData={tenantData}
                loading={loading}
              />
            ) : (
              ""
            )}
          </div>
        </Row>
        <Row className="m-0">
          <Col>
            <div className="financeOverview">
              <PieChart
                data={expensebycategoryArr}
                fin_monthValue={fin_monthValue}
                setFin_MonthValue={setFin_MonthValue}
              />
              <div className="transactions">
                <div className="transactionheader">
                  <span className="overview-main-text">
                    Last 5 Transactions
                  </span>
                </div>

                <Transactions
                  rows={rows}
                  sorting="last-five"
                  paymentMethodsArr={paymentMethodsArr}
                  fetchData={httpFetchDataFn}
                  fetchAndFormatExpensebycategory={
                    fetchAndFormatExpensebycategory
                  }
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
);

export default OverViewUnit;
