import { Row, Col } from "react-bootstrap";
import home_work from "../../assets/home_work.png";
import { users } from "../../assets/index";
import meeting_room from "../../assets/meeting_room.png";

export const ServiceRequestInfo = ({ serviceReqInfo }) => {
  return (
    <>
      <Row className="singleTenantNameDetailsLeftSide">
        <div style={{ paddingLeft: "25px" }}>
          <Col>
            <hr className="hr" />
            <div className="singleTenantUnitOr">
              <span className="unitNameText">
                <img
                  height={16}
                  width={16}
                  src={home_work}
                  style={{ marginRight: "4px" }}
                />
                {serviceReqInfo?.streetAddress}
                {/* {serviceReqInfo?.address?.state} */}
              </span>
              {serviceReqInfo?.unitNumber && (
                <span className="unitAddressText">
                  <img
                    height={16}
                    width={16}
                    src={meeting_room}
                    style={{ marginRight: "4px" }}
                  />
                  {serviceReqInfo?.unitNumber}
                </span>
              )}
              {serviceReqInfo?.tenantName && (
                <span className="unitNameText">
                  <img
                    height={16}
                    width={16}
                    src={users}
                    style={{ marginRight: "4px" }}
                  />
                  {serviceReqInfo?.tenantName}
                </span>
              )}
            </div>
          </Col>
        </div>
      </Row>
    </>
  );
};
