import BlackBed from "../assets/black_bed.svg"
import BlackShower from "../assets/black_shower.svg"
import BlackArea from "../assets/black_area.svg"
import BlueMore from "../assets/blue_more.svg"
import BlueReply from "../assets/blue_reply.svg"
import BlueStar from "../assets/blue_star.svg"
import FilledBlueStar from "../assets/filled_star.svg"

import Blackcross from "../assets/black_cross.svg"
import BlackAdd from "../assets/black_add.svg"
import BlueAdd from "../assets/blue_add.svg"
import WhiteAdd from "../assets/white_add.svg"
import Search from "../assets/search.svg"
import BlackUser from "../assets/user.svg"
import OrangeUser from "../assets/orangeUser.svg"
import ChevronLeft from "../assets/chevron-left.svg"
import ChevronRight from "../assets/chevron-right.svg"
import ChevronDown from "../assets/chevren-down.svg"
import BlackChevronUp from "../assets/black_upchervon.svg"
import OrangeUnit from "../assets/orangeList.svg"
import OrangeList from "../assets/orangeLease.svg"
import OrangeDoller from "../assets/orange_doller.svg"
import RedArrowOutward from "../assets/arrow_outward (1).svg"
import RedArrowInward from "../assets/red_arrow_inward.svg"
import GreenArrowOutward from "../assets/green_arrow_outward.svg"
import Filter from "../assets/filter_list.svg"
import WhiteForwardArrow from "../assets/whiteForwardArrow.svg"

//Mayur Icons and images
import loadingGif from "../assets/Button_animation.gif"
import background from "../assets/backgroundGif.gif"
import smart from "../assets/smart.svg"
import finance from "../assets/finance.svg"
import simplified from "../assets/simplified.svg"
import hidePasswordIcon from "../assets/hidePassword.svg"
import showPasswordIcon from "../assets/showPassword.svg"
import home from "../assets/home.svg"
import upload from "../assets/upload.svg"
import dollarSign from "../assets/dollar.svg"
import pdfImage from "../assets/pdf.svg"
import leftChevronBlack from "../assets/chevronLeftBlack.svg"
import redArrow from "../assets/redDownArrow.svg"
import goToWebsiteIcon from "../assets/goToWebsiteIcon.svg"
import closeIcon from "../assets/closeIcon.svg"
import incomingRentIcon from "../assets/incomingRentIcon.svg"
import time from "../assets/time.svg"
import homeIcon from "../assets/homeIcon.svg"
import mail from "../assets/mail.svg"
import phone from "../assets/call.svg"
import dollarAvatar from "../assets/dollarAvatar.svg"
import vacant from "../assets/vacant.svg"
import success from "../assets/success_check.svg"
import sort from "../assets/sort.svg"
import filter from "../assets/filter.svg"
import homeIconBlue from "../assets/homeIconBlue.svg"
import homeIconBlack from "../assets/homeIconBlack.svg"
import sharpDollarIcon from "../assets/sharpDollar.svg"
import blueDollarIcon from "../assets/blueDollarSign.svg"
import blueUser from "../assets/blueUser.svg"
import phoneGrey from "../assets/phoneGrey.svg"
import mailGrey from "../assets/mailGrey.svg"
import plaidIcon from "../assets/plaidIcon.svg"
import blueForwardArrow from "../assets/blueForwardArrow.svg"
import plaidRight from "../assets/plaidRight.svg"
import delete1 from "../assets/deletePayment.svg"
import edit from "../assets/editPayment.svg"
import creditCardIcon from "../assets/creditCardIcon.svg"
import achIcon from "../assets/achIcon.svg"
import deleteIconRed from "../assets/deleteIconRed.svg"
import unitIconOrange from "../assets/unitIconOrange.svg"
import orangeContractor from "../assets/orangeContractor.svg"
import unitIconMenu from "../assets/unitIconMenu.svg"
import dashboardIconMenu from "../assets/dashboardIconMenu.svg"
import propertiesIconMenu from "../assets/propertyIconMenu.svg"
import tenantIconMenu from "../assets/tenantIconMenu.svg"
import contractorIconMenu from "../assets/contractorIconMenu.svg"
import accountingIconMenu from "../assets/accountingIconMenu.svg"
import serviceRequestIconMenu from "../assets/serviceRequestedIconMenu.svg"
import selectedUnitIconMenu from "../assets/selectedUnitIconMenu.svg"
import selectedDashboardIconMenu from "../assets/selectedDashboardIconMenu.svg"
import selectedPropertiesIconMenu from "../assets/selectedPropertyIconMenu.svg"
import selectedTenantIconMenu from "../assets/selectedTenantIconMenu.svg"
import selectedContractorIconMenu from "../assets/selectedContractorIconMenu.svg"
import selectedServiceRequestIconMenu from "../assets/selectedServiceRequestIconMenu.svg"
import selectedAccountingIcon from "../assets/selectedAccountingIconMenu.svg"
import SingleFamily from "../assets/single_family.svg"
import MultiFamily from "../assets/multi_family.svg"
import Mall from "../assets/mall.svg"
import Apartment from "../assets/Apartment.svg"
import Building from "../assets/building.svg"
import GreyUnit from "../assets/grey_unit.svg"
import BlueReplace from "../assets/blue_replace.svg"
import BlackMinus from "../assets/black_minus.svg"
import notificatioIcon from "../assets/notifications.svg"
import totalTenantAvatar from "../assets/totalTenantAvatar.svg"
import serviceRequestAvatar from "../assets/serviceRequestAvatar.svg"
import homeAvatar from "../assets/homeAvatar.svg"
import calenderIcon from "../assets/calendar.svg"
import leaseAgreementAvatar from "../assets/leaseAgreementAvatar.svg"
import renewalAvatar from "../assets/renewalAvatar.svg"
import BlackDelete from "../assets/black_delete.svg"
import EmailForward from "../assets/emailforward.svg"
import BlackBold from "../assets/black_bold.svg"
import BlueBold from "../assets/blue_bold.svg"
import BlackItalic from "../assets/black_Italic.svg"
import BlueItalic from "../assets/blue_Italic.svg"
import BlackAttachemnet from "../assets/black_attachment.svg"
import Google from "../assets/google.svg"
import AddEmail from "../assets/add_email.svg"
import AddProperty from "../assets/addProperty.svg"
import AddUnitTannet from "../assets/add_unittanent.svg"
import AddPayment from "../assets/add_payment.svg"
import outgoingRent from "../assets/outgoingRentIcon.svg"
import other from "../assets/otherIcon.svg"
import plumbing from "../assets/plumbingIcon.svg"
import electrical from "../assets/electricalIcon.svg"
import exterior from "../assets/exteriorIcon.svg"
import interior from "../assets/interiorIcon.svg"
import appliance from "../assets/appliance.svg"
import placeholder from "../assets/unit_placeholder_icon.svg"
import collapseAbleIcon from "../assets/collapsableIcon.svg"
import alreadyCollapsed from "../assets/alreadyCollapsed.svg"
import leftSideLogoSmall from "../assets/leftSideSmallLogo.svg"
import rightSideLogoSmall from "../assets/rightSideSmallLogo.svg"
import vector from "../assets/Vector.svg"
import noContractor from "../assets/noContractor.svg"
import editVector from "../assets/EditVector.svg"
import handyMan from "../assets/handyman.svg"
import editVector1 from "../assets/editVector1.svg"
import handyMan1 from "../assets/handyman1.svg"
import comment from "../assets/Comment.svg"
import unitIcon from "../assets/AddUnitProperty.svg"
import bed from "../assets/bed.svg"
import shower from "../assets/shower.svg"
import square from "../assets/sq.svg"
import ArrowForward from "../assets/arrow_forward.png"

export const GetImages = {
	BlackBed: BlackBed,
	BlackShower,
	BlackArea,
	BlueMore,
	BlueReply,
	BlueStar,
	FilledBlueStar,
	SingleFamily,
	MultiFamily,
	Mall,
	placeholder,
	Apartment,
	Building,
	GreyUnit,
	BlueReplace,
	BlackMinus,
	BlackDelete,
	EmailForward,
	BlackBold,
	BlueBold,
	BlackItalic,
	BlueItalic,
	BlackAttachemnet,
	Google,
	AddEmail,
	AddProperty,
	AddUnitTannet,
	AddPayment,
	Blackcross: Blackcross,
	BlackAdd: BlackAdd,
	BlueAdd: BlueAdd,
	WhiteAdd: WhiteAdd,
	Search: Search,
	BlackUser: BlackUser,
	OrangeUser: OrangeUser,
	BlackChevronLeft: ChevronLeft,
	BlackChevronRight: ChevronRight,
	BlackChevronDown: ChevronDown,
	BlackChevronUp: BlackChevronUp,
	OrangeUnit: OrangeUnit,
	OrangeList: OrangeList,
	OrangeDoller: OrangeDoller,
	RedArrowOutward: RedArrowOutward,
	RedArrowInward: RedArrowInward,
	GreenArrowOutward: GreenArrowOutward,
	WhiteForwardArrow: WhiteForwardArrow,
	Filter: Filter,

	//mayur icons and pics
	loadingGif: loadingGif,
	background: background,
	smart: smart,
	finance: finance,
	simplified: simplified,
	hidePasswordIcon: hidePasswordIcon,
	showPasswordIcon: showPasswordIcon,
	home: home,
	upload: upload,
	greyDollarSign: dollarSign,
	pdfImage: pdfImage,
	leftChevronBlack: leftChevronBlack,
	redArrow: redArrow,
	goToWebsiteIcon: goToWebsiteIcon,
	closeIconBlack: closeIcon,
	incomingRentIconGreen: incomingRentIcon,
	time: time,
	homeIconOrange: homeIcon,
	mailIconOrange: mail,
	phoneIconOrange: phone,
	dollarAvatar: dollarAvatar,
	vacant: vacant,
	success: success,
	sort: sort,
	filter: filter,
	homeIconBlue: homeIconBlue,
	homeIconBlack: homeIconBlack,
	sharpDollarIcon: sharpDollarIcon,
	blueDollarIcon: blueDollarIcon,
	blueUser: blueUser,
	phoneGrey: phoneGrey,
	mailGrey: mailGrey,
	plaidIcon: plaidIcon,
	blueForwardArrow: blueForwardArrow,
	plaidRight: plaidRight,
	delete1: delete1,
	edit: edit,
	creditCardIcon: creditCardIcon,
	achIcon: achIcon,
	deleteIconRed: deleteIconRed,
	unitIconOrange: unitIconOrange,
	orangeContractor: orangeContractor,
	unitIconMenu: unitIconMenu,
	dashboardIconMenu: dashboardIconMenu,
	propertiesIconMenu: propertiesIconMenu,
	tenantIconMenu: tenantIconMenu,
	contractorIconMenu: contractorIconMenu,
	accountingIconMenu: accountingIconMenu,
	serviceRequestIconMenu: serviceRequestIconMenu,
	selectedUnitIconMenu: selectedUnitIconMenu,
	selectedDashboardIconMenu: selectedDashboardIconMenu,
	selectedPropertiesIconMenu: selectedPropertiesIconMenu,
	selectedTenantIconMenu: selectedTenantIconMenu,
	selectedContractorIconMenu: selectedContractorIconMenu,
	selectedServiceRequestIconMenu: selectedServiceRequestIconMenu,
	selectedAccountingIcon: selectedAccountingIcon,
	notificatioIcon: notificatioIcon,
	totalTenantAvatar: totalTenantAvatar,
	serviceRequestAvatar: serviceRequestAvatar,
	homeAvatar: homeAvatar,
	calenderIcon: calenderIcon,
	leaseAgreementAvatar: leaseAgreementAvatar,
	renewalAvatar: renewalAvatar,
	outgoingRent: outgoingRent,
	Other: other,
	Plumbing: plumbing,
	Electrical: electrical,
	Exterior: exterior,
	Interior: interior,
	Appliance: appliance,
	collapseAbleIcon: collapseAbleIcon,
	alreadyCollapsed: alreadyCollapsed,
	leftSideLogoSmall: leftSideLogoSmall,
	rightSideLogoSmall: rightSideLogoSmall,
	vector: vector,
	noContractor: noContractor,
	editVector: editVector,
	handyMan: handyMan,
	editVector1: editVector1,
	handyMan1: handyMan1,
	comment: comment,
	unitIcon: unitIcon,
	bed: bed,
	shower: shower,
	square: square,
	ArrowForward
}
