import { Row, Col, Form, Button, Table, Offcanvas } from "react-bootstrap";
import { GetImages } from "../../utils/GetImages";
import { useEffect, useState } from "react";
import EditEstimateForm from "./EditEstimateForm";
import ScheduleConfirmationModal from "./ScheduleFixConfirmationModal";
import { ServiceRequestService } from "@propertelligent/client-api";
import { useParams } from "react-router";
import {
  setShowSuccessAlert,
  setShowSuccessMessage,
} from "../../redux/actions/commanAction";
import { useDispatch } from "react-redux";

const AssociateServiceReqWithContractors = ({
  associateData,
  associatedContractors,
  handleContractorChange,
  contractorDataOptions,
  handleSubmitScheduleFix,
  handleSubmitAssociateContractor,
  handleRemoveContractor,
  handleAddContractor,
  setCurrentStep,
  handleUpdateContractor,
  reassign,
  latestContractor,
}) => {
  const btnStyle = {};

  const [selectedRow, setSelectedRow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [reassignConf, setReassignConf] = useState(false);
  const [contractorId, setContractorId] = useState();
  const [estimatedCost, setEstimatedCost] = useState();
  const [contractorsList, setContractorList] = useState([]);
  const [selectedContractorId, setSelectedContractorId] = useState();
  const [assId, setAssId] = useState();
  const { id } = useParams();
  const dispatch: any = useDispatch();

  const handleRowSelection = (index) => {
    setSelectedRow(index);
  };

  const handleRowClick = (index) => {
    setSelectedRow(index);
  };

  // Static data for contractors
  const staticContractors = [
    { id: 1, contractorName: "John Carter", estimate: "$400" },
    { id: 2, contractorName: "Mark Zuckerberg", estimate: "$400" },
    { id: 3, contractorName: "Elon musk", estimate: "$400" },
    { id: 4, contractorName: "Tim cock", estimate: "$400" },
  ];

  const formDetails = [
    {
      label: "Contractor name",
      placeholder: "Enter first name",
      value: staticContractors.map((contractor) => contractor.contractorName),
      name: "firstName",
    },
    {
      label: "Estimate",
      placeholder: "Enter last name",
      value: staticContractors.map((contractor) => contractor.estimate),
      name: "lastName",
    },
  ];

  const handleClose = () => {
    setShowConfirmation(false);
  };

  const handleResAssClose = () => {
    setReassignConf(false);
  };

  const contractorName = () => {
    if (selectedRow !== null) {
      const selectedContractor = contractorsList[selectedRow].contractor;
      return selectedContractor.firstName + " " + selectedContractor.lastName;
    }
    return "";
  };

  let obj = {
    serviceRequestId: Number(id),
    associateContractorId: Number(selectedContractorId),
    name: contractorName(),
  };

  const associateContractorsToServiceRequest = () => {
    let Obj = {
      serviceRequestId: Number(id),
      contractorId: Number(contractorId),
      estimatedCost: Number(estimatedCost),
    };
    ServiceRequestService.postApiServiceRequestAssociate(Obj)
      .then((response: any) => {
        if (response?.success) {
          handleAddContractor({ ...Obj, id: response.success.id });
          dispatch(setShowSuccessAlert(true));
          dispatch(setShowSuccessMessage("Estimate Added successfully"));
          setTimeout(() => {
            dispatch(setShowSuccessAlert(false));
          }, 3000);
        }
      })
      .catch((error) => {});
  };

  const handleChangeContractor = (value: any) => {
    setContractorId(value);
  };

  const handleChangeContractorEstimate = (value: any) => {
    setEstimatedCost(value);
  };

  useEffect(() => {
    getAssociatedContractorList(associatedContractors, contractorDataOptions);
  }, [associatedContractors, contractorDataOptions]);

  const getAssociatedContractorList = (
    associatedContractors,
    allContractors
  ) => {
    const contractorList = associatedContractors.map((assocContractor) => {
      const contractor = allContractors.find(
        (contractor) => contractor.id === assocContractor.contractorId
      );
      return {
        contractor,
        estimatedCost: assocContractor.estimatedCost,
        id: assocContractor.id,
      };
    });
    setContractorList(contractorList);
  };

  const handleModalShow = (value, contractorId, assId) => {
    setShowModal(value);
    setSelectedContractorId(contractorId);
    setAssId(assId);
  };

  const handleSchedule = (value, contractorId, firstName, lastName) => {
    const name = firstName + " " + lastName;

    if (name === latestContractor) {
      setReassignConf(true);
      setShowConfirmation(false);
    } else {
      setShowConfirmation(true);
      setSelectedContractorId(contractorId);
    }
  };

  return (
    <>
      <div style={{ width: "auto" }}>
        <div className="newTenantDesciptionContainer mt-4 border-0">
          <p className="newTenantDescriptionText">Add contractor</p>
        </div>
        <div className="parentDiv w-100">
          <div className="w-100">
            <div
              style={{ minWidth: "90px" }}
              className="d-flex align-items-end gap-2 mb-3 justify-content-start flex-wrap"
            >
              <div style={{ width: "164px" }}>
                <Form.Group>
                  <Form.Label className="labelTextAddUnitTenant">
                    Select Contractor
                  </Form.Label>
                  <Form.Select
                    className="selectTagAddUnitTenant"
                    required
                    value={contractorId}
                    onChange={(e) => handleChangeContractor(e.target.value)}
                  >
                    <option value="">Select Contractor</option>
                    {contractorDataOptions.map((cont) => (
                      <option key={cont.id} value={cont.id}>
                        {`${cont.firstName} ${cont.lastName}`}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
              <div style={{ width: "164px" }}>
                <Form.Group>
                  <Form.Label className="labelTextAddUnitTenant">
                    Estimate*
                  </Form.Label>
                  <Form.Control
                    className="containerTextField"
                    type="number"
                    required
                    value={estimatedCost}
                    onChange={(e) =>
                      handleChangeContractorEstimate(e.target.value)
                    }
                    placeholder="Enter Estimate"
                  />
                </Form.Group>
              </div>
              <div style={{ width: "124px" }}>
                <div className="div button-group">
                  <Form.Group>
                    <Button
                      variant="light"
                      onClick={() => associateContractorsToServiceRequest()}
                      className="associate-button"
                    >
                      {"Associate"}
                      <img src={GetImages.blueForwardArrow} />
                    </Button>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {contractorsList.length > 0 ? (
        <Table bordered hover>
          <thead>
            <tr>
              <th className="associateTableHeading"></th>
              <th className="associateTableHeading">Contractor</th>
              <th className="associateTableHeading">Estimate</th>
              <th className="associateTableHeading">Actions</th>
            </tr>
          </thead>
          <tbody>
            {contractorsList &&
              contractorsList.map((contractor, index) => (
                <tr
                  key={contractor?.contractor?.id + "-" + index}
                  className="tableRow"
                  onClick={() => handleRowClick(index)}
                >
                  <td className="associateTable">
                    <Form.Check
                      type="radio"
                      name="selectRow"
                      onChange={() => handleRowSelection(index)}
                      checked={selectedRow === index}
                    />
                  </td>
                  <td className="associateTable">
                    {contractor?.contractor?.firstName +
                      " " +
                      contractor?.contractor?.lastName}
                  </td>
                  <td className="associateTable">
                    {contractor?.estimatedCost}
                  </td>
                  <td className="associateTable">
                    <Button
                      variant="none"
                      onClick={() =>
                        handleModalShow(
                          true,
                          contractor?.contractor?.id,
                          contractor?.id
                        )
                      }
                      disabled={contractorsList.length < 2}
                    >
                      {selectedRow === index ? (
                        <img src={GetImages.editVector} alt="Edit contractor" />
                      ) : (
                        <img
                          src={GetImages.editVector1}
                          alt="Edit contractor"
                        />
                      )}
                    </Button>
                    <Button
                      variant="none"
                      onClick={() =>
                        handleSchedule(
                          true,
                          contractor?.id,
                          contractor?.contractor?.firstName,
                          contractor?.contractor?.lastName
                        )
                      }
                    >
                      {selectedRow === index ? (
                        <img src={GetImages.handyMan} alt="Schedule fix" />
                      ) : (
                        <img src={GetImages.handyMan1} alt="Schedule fix" />
                      )}
                    </Button>{" "}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : (
        <div className="d-flex justify-content-center align-items-center flex-column m-5">
          <img src={GetImages.noContractor} alt="No Contractor" />
          <h3 className="noContractorHeading">No Contractor Added!</h3>
          <p className="noContractorDes">
            Need to Select Contractor & Estimate
          </p>
        </div>
      )}

      <Offcanvas
        className="offcanvas"
        show={showModal}
        onHide={() => setShowModal(false)}
        placement={"end"}
        scroll={true}
      >
        <Offcanvas.Header className="canvasHeader" closeButton>
          <Offcanvas.Title>Edit Estimate </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {selectedRow !== null && ( // Check if a div is selected
            <EditEstimateForm
              id={selectedContractorId} // Pass the ID of the selected contractor
              setShowModal={setShowModal}
              setShowSuccessAlert={setShowSuccessAlert}
              setShowSuccessMessage={setShowSuccessMessage}
              setAlertMessage={setAlertMessage}
              contractorsList={contractorsList}
              serviceReqId={id}
              handleRemoveContractor={handleRemoveContractor}
              handleAddContractor={handleAddContractor}
              assId={assId}
              handleUpdateContractor={handleUpdateContractor}
            />
          )}
        </Offcanvas.Body>
      </Offcanvas>

      {showConfirmation ? (
        <ScheduleConfirmationModal
          onSchedule={() => handleSubmitScheduleFix(obj, true)} // Pass true for scheduling fix
          onClose={handleClose}
          label="Confirm & Proceed"
          heading={`Are you sure to schedule fix with "${contractorName()}"?`}
          subHeading={"This will notify the contractor for the assigned task."}
        />
      ) : (
        reassignConf && (
          <ScheduleConfirmationModal
            onSchedule={() => handleSubmitScheduleFix(obj, false)} // Pass false for reassigning contractor
            onClose={handleResAssClose}
            label="Confirm"
            heading={"Are you sure to reassign contractor"}
            subHeading={
              "This action will reassign the existing contractor with the new one"
            }
          />
        )
      )}
    </>
  );
};

export default AssociateServiceReqWithContractors;
