import React, { useEffect, useRef } from "react";
import EmptyData from "../../common/EmptyData";

const PieChart = ({ data, fin_monthValue, setFin_MonthValue }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = Math.min(centerX, centerY);

    const drawDoughnutSegment = (startAngle, endAngle, color, innerRadius) => {
      context.fillStyle = color;
      context.beginPath();
      context.arc(centerX, centerY, radius, startAngle, endAngle);
      context.arc(centerX, centerY, innerRadius, endAngle, startAngle, true);
      context.closePath();
      context.fill();
    };

    const degreesToRadians = (degrees) => (degrees * Math.PI) / 180;

    context.clearRect(0, 0, canvas.width, canvas.height);

    const total = data?.reduce((acc, item) => acc + item.value, 0);

    const ringThickness = 20;
    const innerRadius = radius - ringThickness;

    let startAngle = 0;
    data?.forEach((item) => {
      const percentage = (item?.value / total) * 360;
      const endAngle = startAngle + degreesToRadians(percentage);
      drawDoughnutSegment(startAngle, endAngle, item?.color, innerRadius);
      startAngle = endAngle;
    });
  }, [data, fin_monthValue]);

  return (
    <>
      <div className="expenseDistribution  ">
        <div className="expenseHeader">
          <p className="FW700_LH24_main_text">Expense Distribution</p>
          <div className="monthhead w-100 justify-content-">
            <button
              onClick={() => setFin_MonthValue(-1)}
              className={
                fin_monthValue === -1
                  ? "overviewSelectedMonth "
                  : "overviewUnselectedMonth"
              }
            >
              Last month
            </button>
            <button
              onClick={() => setFin_MonthValue(-6)}
              className={
                fin_monthValue === -6
                  ? "overviewSelectedMonth"
                  : "overviewUnselectedMonth"
              }
            >
              Last 6 month
            </button>
            <button
              onClick={() => setFin_MonthValue(-12)}
              className={
                fin_monthValue === -12
                  ? "overviewSelectedMonth"
                  : "overviewUnselectedMonth"
              }
            >
              Last 12 Months
            </button>
          </div>
        </div>

        <canvas ref={canvasRef} width={150} height={150} className="m-3" />
        <div className="expenseDetails" style={{overflow: 'hidden'}}>
          {data?.length === 0 ? (
            <>
              <EmptyData
                mainText={undefined}
                subText="Data Not Available"
                button={undefined}
              />
            </>
          ) : (
            data?.map((item, index) => (
              <div className="expenseItem" key={index}>
                <div className="propertyCardQuantityDesc ">
                  <div
                    className="expenseColor"
                    style={{ background: item?.color }}
                  >
                    {" "}
                  </div>
                  <p className="FW400_LH20_small_text ms-2 "> {item?.name}</p>
                </div>
                <div className="expenseValue ">
                  <p className="FW700_LH16_main_text">
                    {(
                      (item?.value /
                        data?.reduce((acc, item) => acc + item?.value, 0)) *
                      100
                    ).toFixed(2)}
                    % (${item?.value})
                  </p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default PieChart;
