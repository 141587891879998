import React, { ChangeEvent, useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { mobileNumberRegex, nameRegex } from "../common/Regex"
import EditCustomComponent from "../tenants/EditCustomComponent"
import { ServiceRequestService } from "@propertelligent/client-api"
import { GetImages } from "../../utils/GetImages"
import UpdateEstimateConfirmModal from "./UpdateEstimateConfirmModal"


const EditEstimateForm = ({ id, setShowModal, setShowSuccessAlert, setShowSuccessMessage, setAlertMessage, contractorsList, serviceReqId, handleRemoveContractor, handleAddContractor, assId, handleUpdateContractor }) => {
	const [contractorNameError, setContractorNameError] = useState<boolean>(false);
	const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false)
	const [showConfirmation, setShowConfirmation] = useState(false)

	const dispatch: any = useDispatch();

	const [values, setValues] = useState({
		contractorName: "",
		estimate: "",
		id: null,
	});

	// Find the contractor with the matching id
	useEffect(() => {
		const contractor = contractorsList.find((contractor) => contractor?.contractor?.id === id)
		if (contractor) {
			// Set the state with the data of the matched contractor
			setValues({
				contractorName: contractor?.contractor?.firstName + ' ' + contractor?.contractor?.lastName,
				estimate: contractor?.estimatedCost,
				id: contractor?.contractor?.id,
			})
		}
	}, [id])

	const [errorMessages, setErrorMessages] = useState({
		contractorName: "This field is required",
		phoneNumber: "This field is required",
	})

	const validateFirstName = (): boolean => {
		if (values.contractorName.length === 0) {
			setContractorNameError(false)
			return false
		}

		if (values.contractorName.trim().length > 0) {
			if (nameRegex.test(values.contractorName)) {
				setContractorNameError(false)
			} else if (values.contractorName.trim().length < 2 || values.contractorName.trim().length > 20) {
				setContractorNameError(true)
				setErrorMessages({
					...errorMessages,
					contractorName: "First Name should have 2-20 characters",
				})
			} else {
				setContractorNameError(true)
				setErrorMessages({
					...errorMessages,
					contractorName: "Please enter letters only",
				})
				return true
			}
		}

		return false
	}

	const validateNumber = (): boolean => {
		if (values.estimate.length > 0) {
			if (mobileNumberRegex.test(values.estimate)) {
				setPhoneNumberError(false)
			} else {
				setPhoneNumberError(true)
				setErrorMessages({
					...errorMessages,
					phoneNumber: "Please enter correct phone number",
				})
				return true
			}
		}

		return false
	}

	const formDetails = [
		{
			label: "Contractor Name",
			placeholder: "Enter Contractor Name",
			value: values.contractorName,
			name: "contractorName",
			blurFunction: validateFirstName,
			errorMessages: errorMessages.contractorName,
			isError: contractorNameError,
			disabled: true
		},
		{
			label: "Estimate",
			placeholder: "Enter Estimate",
			value: values.estimate,
			name: "estimate",
			blurFunction: validateNumber,
			errorMessages: errorMessages.contractorName,
			isError: contractorNameError,
		},
	]

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target
		switch (name) {
			case "contractorName":
				setContractorNameError(false)
				setErrorMessages({
					...errorMessages,
					contractorName: "This field is required",
				})
				break
			case "Estimate":
				setPhoneNumberError(false)
				setErrorMessages({
					...errorMessages,
					phoneNumber: "This field is required",
				})
				break
		}

		setValues({ ...values, [name]: value })
	}

	const editSubmitHandler = (e) => {
		e.preventDefault();
	}

	const handleFunction = () => {
		setShowModal(false);
		setShowConfirmation(true);
	}

	const handleClose = () => {
		setShowConfirmation(false)
	}

	let obj = {
		serviceRequestId: Number(serviceReqId),
		associateContractorId: Number(assId)
	}

	const handleRemove = () => {
		handleRemoveContractor(obj)
		setShowModal(false)
	}

	const associateContractorsToServiceRequest = () => {
		let Obj = {
			id: Number(assId),
			serviceRequestId: Number(serviceReqId),
			contractorId: Number(values.id),
			estimatedCost: Number(values.estimate),
		};
		ServiceRequestService.postApiServiceRequestAssociate(Obj)
			.then((response: any) => {
				if (response?.success) {
					handleUpdateContractor({ ...Obj, id: response.success.id })
					dispatch(setShowSuccessAlert(true));
					dispatch(setShowSuccessMessage("Estimate Update successfully"));
					setTimeout(() => {
						dispatch(setShowSuccessAlert(false));
					}, 3000);
				}
			})
			.catch((error) => {});
	};

	return (
		<div>
			<form onSubmit={editSubmitHandler}>
				<div className="editTenantEstimatentainer">
					{formDetails.map((item) => (
						<EditCustomComponent
							key={item.label}
							label={item.label}
							placeholder={item.placeholder}
							value={item.value}
							name={item.name}
							onChangeFunction={handleChange}
							onBlurFunction={item.blurFunction}
							errorMessages={item.errorMessages}
							isError={item.isError}
							disabled={item.disabled}
						/>
					))}
				</div>

				<div className="editEstimateFormFooter">
					<p onClick={handleRemove} className="removeContractor clickable">
						Remove Contractor
					</p>
					<div className="d-flex justify-content-between align-items-center w-50">
						<p onClick={() => setShowModal(false)} className="cancelEdit clickable">
							Cancel
						</p>
						<button type="submit" className="saveEditTenantButton" onClick={handleFunction}>
							Save Changes <img src={GetImages.WhiteForwardArrow} />
						</button>
					</div>
				</div>
			</form>
			{showConfirmation && <UpdateEstimateConfirmModal contractorName={values.contractorName} onClose={handleClose} btnName={"Confirm & Proceed"} associateContractorsToServiceRequest={associateContractorsToServiceRequest} />}
		</div>
	)
}

export default EditEstimateForm
