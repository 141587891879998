// import React from "react";
// import homeIcon from "../../assets/homeIcon.png";
// import { convertDate } from "../../constants/constants";

// interface ServiceRequest {
//   formatted?: {
//     ServiceType?: string;
//     RequestDateDateOnly: Date;
//     address: string;
//   };
// }

// interface RecentServiceRequestComponentProps {
//   data: ServiceRequest[];
// }

// const RecentServiceRequestComponent = ({
//   data,
// }: RecentServiceRequestComponentProps) => {
//   return (
//     <div className="d-flex flex-column gap-2">
//       {data.slice(0, 5).map((serviceRequest, index) => (
//         <div
//           key={index}
//           className="recentServiceRequestComponentStyles d-flex align-items-center justify-content-between flex-wrap"
//         >
//           <div className="d-flex flex-column gap-2">
//             <div className="outletRepairComponent ms-0 d-flex gap-1  ">
//               <p>{serviceRequest?.formatted?.ServiceType}</p>
//             </div>
//             <div className="d-flex align-items-center imgAddressContainer">
//               <img src={homeIcon} alt="Home Icon" />
//               <p className="typeMailPhoneText">
//                 {serviceRequest?.formatted?.address}
//               </p>
//             </div>
//           </div>
//           <p className="sinceTimePeriod">
//             {convertDate(serviceRequest?.formatted?.RequestDateDateOnly)}
//           </p>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default RecentServiceRequestComponent;

import React from "react";
import homeIcon from "../../assets/homeIcon.png";
import { convertDate } from "../../constants/constants";

interface ServiceRequest {
  address: null;
  formatted?: {
    ServiceType?: string;
    ServiceSubType?: string;
    RequestDateDateOnly: Date;
    address: string;
  };
}

interface RecentServiceRequestComponentProps {
  data: ServiceRequest[];
}

const RecentServiceRequestComponent = ({
  data,
}: RecentServiceRequestComponentProps) => {
  return (
    <div className="d-flex flex-column gap-2">
      {data.slice(0, 5).map((serviceRequest, index) => (
        <div
          key={index}
          className="recentServiceRequestComponentStyles d-flex align-items-center justify-content-between flex-wrap"
        >
          <div className="d-flex flex-column gap-2">
            <div className="outletRepairComponent ms-0 d-flex gap-1  ">
              <p>
                {serviceRequest?.formatted?.ServiceType} :{" "}
                {serviceRequest?.formatted?.ServiceSubType}
              </p>
            </div>
            <div className="d-flex align-items-center imgAddressContainer">
              <img src={homeIcon} alt="Home Icon" />
              <p className="typeMailPhoneText">
                {serviceRequest?.address == null
                  ? "5250 Truman Dr, Decatur, GA 30035, United States"
                  : serviceRequest?.address}
              </p>
            </div>
          </div>
          <p className="sinceTimePeriod">
            {convertDate(serviceRequest?.formatted?.RequestDateDateOnly)}
          </p>
        </div>
      ))}
    </div>
  );
};

export default RecentServiceRequestComponent;
