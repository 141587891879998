// import React, { useEffect, useState } from "react";
// import Overview from "./overview";
// import UnitList from "./unitList";
// import ServiceRequestList from "../../common/ServiceRequestList";
// import {
//   EntityType,
//   LookupService,
//   LookupTypesEnum,
//   PaymentService,
//   ServiceRequestService,
// } from "@propertelligent/client-api";
// import { UserLoginData } from "../../common/charts/Enums";

// const Tabs = ({ id, activeIndex, setActiveIndex }) => {
//   // const [activeIndex, setActiveIndex] = useState(1);
//   const [paymentMethodsArr, setPaymentMethodsArr] = useState([]);
//   const [payeeAccountsArr, setPayeeAccountsArr] = useState([]);
//   const handleClick = (index) => setActiveIndex(index);
//   const checkActive = (index, className) =>
//     activeIndex === index ? className : "";

//   useEffect(() => {
//     const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));

//     LookupService.getApiLookup1(
//       LookupTypesEnum.PaymentTypes,
//       EntityType.Property
//     ).then((res) => {
//       if (res?.success) {
//         setPaymentMethodsArr(res?.success);
//       }
//     });

//     PaymentService.getApiPayeeAccountOrganizationlist(orgId)
//       .then((ptRes) => {
//         if (ptRes?.success) {
//           setPayeeAccountsArr(ptRes?.success);
//         }
//       })
//       .catch((ptErr) => { });
//   }, [id]);

//   return (
//     <>
//       <div className="p-tabs">
//         <button
//           className={`tab FW400_LH20_small_text ${checkActive(1, "active ")}`}
//           onClick={() => handleClick(1)}
//         >
//           Overview
//         </button>
//         <button
//           className={`tab FW400_LH20_small_text ${checkActive(2, "active")}`}
//           onClick={() => handleClick(2)}
//         >
//           Units
//         </button>
//         <button
//           className={`tab FW400_LH20_small_text ${checkActive(3, "active")}`}
//           onClick={() => handleClick(3)}
//         >
//           Service Requests
//         </button>
//       </div>
//       <div className="panels ">
//         <div className={`panel ${checkActive(1, "active")}`}>
//           <Overview
//             paymentMethodsArr={paymentMethodsArr}
//             payeeAccountsArr={payeeAccountsArr}
//             entityTypeId={EntityType.Property}
//           />
//         </div>
//         <div className={`panel ${checkActive(2, "active")}`}>
//           <UnitList />
//         </div>
//         <div className={`panel ${checkActive(3, "active")}`} >
//           <ServiceRequestList
//             setTotalServiceRequest={null}
//             id={id}
//             httpFetchDataFn={
//               ServiceRequestService.getApiServiceRequestPropertylist
//             }
//           />
//         </div>
//       </div>
//     </>
//   );
// };

// export default Tabs;

import React, { memo, useEffect, useState } from "react";
import Overview from "./overview";
import UnitList from "./unitList";
import ServiceRequestList from "../../common/ServiceRequestList";
import {
  EntityType,
  LookupService,
  LookupTypesEnum,
  PaymentService,
  ServiceRequestService,
} from "@propertelligent/client-api";
import { UserLoginData } from "../../common/charts/Enums";

const Tabs = ({
  id,
  activeIndex,
  setActiveIndex,
  loadingStates,
  setLoadingStates,
  checkActive
}) => {
  // const [activeIndex, setActiveIndex] = useState(1);
  const [paymentMethodsArr, setPaymentMethodsArr] = useState([]);
  const [payeeAccountsArr, setPayeeAccountsArr] = useState([]);
  // const handleClick = (index) => setActiveIndex(index);
  // const checkActive = (index, className) =>
  //   activeIndex === index ? className : "";

  useEffect(() => {
    const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));
    const propertyId = parseInt(id);

    PaymentService.getApiPaymentMethodPropertylist(propertyId).then((res) => {
      if (res.success) {
        setPaymentMethodsArr(res.success);
      }
    });

    PaymentService.getApiPayeeAccountOrganizationlist(orgId)
      .then((ptRes) => {
        if (ptRes?.success) {
          setPayeeAccountsArr(ptRes?.success);
        }
      })
      .catch((ptErr) => {});

    // LookupService.getApiLookup1(
    //   LookupTypesEnum.PaymentTypes,
    //   EntityType.Property
    // ).then((res) => {
    //   if (res?.success) {
    //     setPaymentMethodsArr(res?.success);
    //   }
    // });
  }, []);

  return (
    <>
      {/* <div className="p-tabs">
        <button
          className={`tab FW400_LH20_small_text ${checkActive(1, "active ")}`}
          onClick={() => handleClick(1)}
        >
          Overview
        </button>
        <button
          className={`tab FW400_LH20_small_text ${checkActive(2, "active")}`}
          onClick={() => handleClick(2)}
        >
          Units
        </button>
        <button
          className={`tab FW400_LH20_small_text ${checkActive(3, "active")}`}
          onClick={() => handleClick(3)}
        >
          Service Requests
        </button>
      </div> */}
        <div className={`panel ${checkActive(1, "active")}`}>
          <Overview
            loadingStates={loadingStates}
            setLoadingStates={setLoadingStates}
            paymentMethodsArr={paymentMethodsArr}
            payeeAccountsArr={payeeAccountsArr}
            entityTypeId={EntityType.Property}
          />
        </div>
        <div className={`panel ${checkActive(2, "active")}`}>
          <UnitList
            loadingStates={loadingStates}
            setLoadingStates={setLoadingStates}
          />
        </div>
        <div className={`panel ${checkActive(3, "active")}`}>
          <ServiceRequestList
            setTotalServiceRequest={null}
            id={id}
            httpFetchDataFn={
              ServiceRequestService.getApiServiceRequestPropertylist
            }
            loadingStates={loadingStates}
            setLoadingStates={setLoadingStates}
          />
        </div>
    </>
  );
};

export default memo(Tabs);
