import React, { useEffect, useRef, useState } from "react";
import "./Dashboard.css";
import TopSection from "./TopSection";
import RevenueOverviewContainer from "./RevenueOverviewContainer";
import OpenActionItemsDashboard from "./OpenActionItemsDashboard";
import CashFlowChart from "./CashFlowChart";
import ExpenseDistributionDashboard from "./ExpenseDistributionDashboard";
import PerformanceOverview from "./PerformanceOverview";
import VacancyRate from "./VacancyRate";
import { UserLoginData } from "../components/common/charts/Enums";
import { useAnalyticsHook } from "../hooks/useAnalyticsHook";
import useWindowDimensions from "../hooks/windowDimensionsHook";
import ScrollingContainer from "../components/common/scrollingContainer/ScrollingContainer";
import { AnalyticsService } from "@propertelligent/client-api";
const Dashboard = () => {
  const organization_id = Number(
    localStorage.getItem(UserLoginData.organization_id)
  );
  const [fin_monthValue, setFin_MonthValue] = useState(-12);
  const [allExpenseData, setAllExpenseData] = useState([]);
  const data = [
    {
      name: "Flooring",
      value: 800,
      color: "#00C769",
    },
    {
      name: "Electric",
      value: 500,
      color: "#FFC400",
    },
    {
      name: "Phone",
      value: 300,
      color: "#FF1F8B",
    },
    {
      name: "Due",
      value: 100,
      color: "#5f0a57",
    },
  ];
  const customMonthField = "monthYear";

  const { expensebycategoryArr } = useAnalyticsHook({
    httpExpensebycategoryFn:
      AnalyticsService.getApiAnalyticsOrganizationExpensebycategory,
    customMonthField,
    parentEntityId: organization_id,
    fullyQualifiedFilters: {
      fin: `Created>DateTime.UtcNow.AddMonths(${fin_monthValue})`,
    },
  });


  return (
    <ScrollingContainer Content={() => {
      return (
        <>
          <div className="dashboardTopBar">
            <div>
              <p className="dashboardHeading">Dashboard</p>
            </div>
          </div>
          <div>
            <TopSection />
          </div>
          <div className="d-flex flex-wrap dashboardBottomContainer">
            <div className="leftSideDashboardComponents">
              <RevenueOverviewContainer />
              <CashFlowChart />
              <ExpenseDistributionDashboard
                data={expensebycategoryArr}
                fin_monthValue={fin_monthValue}
                setFin_MonthValue={setFin_MonthValue}
              />
              <VacancyRate />
            </div>
            <div className="rightSideDashboardComponents">
              {/* <OpenActionItemsDashboard /> */}
              <PerformanceOverview />
            </div>
          </div>
        </>
      )
    }} />
  );
};

export default Dashboard;
