import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "./Dashboard.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { UserLoginData } from "../components/common/charts/Enums";
import { useAnalyticsHook } from "../hooks/useAnalyticsHook";
import { AnalyticsService } from "@propertelligent/client-api";

const CashFlowChart = () => {
  const organization_id = Number(
    localStorage.getItem(UserLoginData.organization_id)
  );
  const customMonthField = "monthYear";
  const [rev_monthValue, setRev_MonthValue] = useState<any>(-12);
  const [cashFlowData, setCashFlowData] = useState([]);
  
  const { cashFlow } = useAnalyticsHook({
    httpCashflowFn: AnalyticsService.getApiAnalyticsOrganizationCashflow,
    customMonthField,
    parentEntityId: organization_id,

    fullyQualifiedFilters: {
      rev: `Created>DateTime.UtcNow.AddMonths(${rev_monthValue})`,
    },
  });

  useEffect(() => {
    const transformedData = convertObjects(cashFlow);

    if (transformedData.length > 0) {
      setCashFlowData(transformedData.reverse());
    }
  }, [cashFlow]);

  function convertObjects(array) {
    return array.map((obj) => ({
      name: formatDate(obj.monthYear),
      Expense: obj.expense,
      Revenue: obj.income, // Assuming revenue is income
    }));
  }

  function formatDate(monthYear) {
    const [month, year] = monthYear.split("/");
    const fullYear = new Date(year, parseInt(month) - 1, 1).getFullYear();
    const shortYear = fullYear.toString().slice(2);

    return new Date(year, parseInt(month) - 1, 1)
      .toLocaleString("default", {
        month: "short",
        year: "numeric",
      })
      .replace(fullYear.toString(), shortYear);
  }

  const formatYAxisTick = (tick) => {
    // Divide the tick value by 1000 and append 'k'
    return tick / 1000 + "k";
  };

  const CustomTooltip = ({
    active,
    payload,
  }: {
    active?: boolean;
    payload?: any[];
  }) => {
    if (active && payload && payload.length) {
      const data1 = payload[0];
      const data2 = payload[1];

      return (
        <div className="toolTipContainer2">
          <div>
            <p className="toolTipHeading">{`${data1.payload.name}`}</p>
          </div>
          <div className="d-flex gap-4 align-items-center vacancyDataContainer ">
            <div>
              <p className="openActionDate1 mb-1">Revenue</p>
              <p className="openActionTitle1">
                {data1.value.toLocaleString("en-US")}
              </p>
            </div>
            <div>
              <p className="openActionDate1 mb-1">Expense</p>
              <p className="openActionTitle1">
                {data2.value.toLocaleString("en-US")}
              </p>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <div className="overviewContainerMain">
        <div className="overviewContainerRightSide">
          <div
            style={{ borderBottom: "none" }}
            className="overviewContainerRightSideTopComponent"
          >
            <p className="rentOverviewHeading">Cash Flow Overview</p>
            <div className="overviewButtons">
              <Form.Select
                className="overview-sub-text"
                aria-label="Default select Service Sub Type"
                required
                onChange={(e) => {
                  setRev_MonthValue(e.target.value);
                }}
                value={rev_monthValue}
              >
                <option value={-3}>Last 3 months </option>
                <option value={-6}>Last 6 months </option>
                <option value={-12}>Last 12 months </option>
              </Form.Select>
            </div>
          </div>
          <div
            className="d-flex justify-content-center firstChartStyles"
            style={{ width: "100%", height: 250 }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={cashFlowData}>
                <XAxis
                  className="chartTextClass"
                  dataKey="name"
                  padding={{ left: 30, right: 30 }}
                >
                  <Label
                    className="chartTextClass"
                    value="Time"
                    position="bottom"
                    offset={-5}
                  />
                </XAxis>
                <YAxis
                  tickFormatter={formatYAxisTick}
                  className="chartTextClass"
                >
                  <Label
                    value="Cash Flow"
                    position="left"
                    angle={-90}
                    offset={0}
                    style={{ textAnchor: "middle" }}
                    className="chartTextClass"
                  />
                </YAxis>
                <Tooltip content={<CustomTooltip />} cursor={false} />{" "}
                <Line type="linear" dataKey="Revenue" stroke="#1A56DB" />
                <Line type="linear" dataKey="Expense" stroke="#FF5A1F" />
                <Legend
                  className="chartTextClass"
                  order="reverse"
                  iconType="circle"
                  iconSize={8}
                  wrapperStyle={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    paddingLeft: 60,
                    fontFamily: "Instrument Sans",
                    fontSize: 12,
                    fontWeight: 400,
                  }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashFlowChart;
