import React, { useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { GetImages } from "../../utils/GetImages"
import SuccessAlert from "../tenants/SuccessAlert"

const UpdateEstimateConfirmModal = ({ onClose, contractorName, btnName, associateContractorsToServiceRequest }) => {
	const [showSuccessAlert, setShowSuccessAlert] = useState(false)
	const handleFunction = () => {
		onClose()
		setShowSuccessAlert(true)
		associateContractorsToServiceRequest()
	}

	return (
		<>
			<div>
				<Modal className="scheduleConfirmationModal d-flex align-items-center justify-content-center" show={true} onHide={onClose}>
					<div className="d-flex flex-column align-items-between justify-content-between h-100">
						<div>
							<div className="d-flex align-items-center justify-content-between">
								<p className="openActionContractorHeading">Are you sure to update estimates.</p>
								<img className="clickable" onClick={onClose} src={GetImages.closeIconBlack} />
							</div>
							<p style={{ whiteSpace: "wrap" }} className="typeMailPhoneText mt-2">
								On confirming you will update the estimate given by contractor "{contractorName}".
							</p>
						</div>
						<div className="d-flex justify-content-end align-items-center gap-3">
							<p onClick={onClose} className="cancelEdit clickable">
								Cancel
							</p>
							<button className="saveChangesButton1" onClick={handleFunction}>
								{btnName}
							</button>
						</div>
					</div>
				</Modal>
			</div>
			{showSuccessAlert && <SuccessAlert message={"Estimates updated successfully"} setShowSuccessAlert={setShowSuccessAlert} />}
		</>
	)
}

export default UpdateEstimateConfirmModal
