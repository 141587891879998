import { Row, Col } from "react-bootstrap";
import calendar from "../../assets/calendar.png";
import { useNavigate } from "react-router-dom";
import Worker from "../../assets/Worker.png";

export const ServiceNotes = ({ serviceReqInfo }) => {
  return (
    <Row
      className="singleTenantNameDetailsLeftSide"
      style={{ paddingLeft: "15px", marginTop: "12px", marginBottom: "12px" }}
    >
      <p className="singleTenantDetailsName">
        <span> Description: </span> {serviceReqInfo}
      </p>
    </Row>
  );
};
