import { useEffect, useState, useContext, useRef } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import UserDashboard from "./components/dashboard";
import { OpenAPI } from "@propertelligent/client-api";
import { ForgetPassword } from "./components/user/ForgetPassword";
import CheckEmail from "./components/user/CheckEmail";
import ResetPassword from "./components/user/ResetPassword";
import { MyContext } from "./utils/context/LoginContext";
import { UserLoginData } from "./components/common/charts/Enums";
import { Login } from "./components/user/Login";
import Signup from "./components/user/Signup";
import { BASE_API_URL, MAIN_ROUTES_URLS, URLS } from "./constants/constants";
import { useDispatch, useSelector } from "react-redux";
import GmailAuthReturn from "./components/Messaging/Email/components/GmailAuthRetrun";
import NewQuestionnaire from "./components/user/NewQuestionnaire";
import SuccessAlert from "./components/tenants/SuccessAlert";
import { setShowSuccessAlert } from "./redux/actions/commanAction";
import CustomMenu from "./layout/CustomMenu";
import CustomTopBar from "./layout/CustomTopBar";
import i18nConfig from "./utils/i18nConfig"; //important to load i18n config

export default function App(): JSX.Element | any {
  const { showSuccessAlert, successMsg } = useSelector(
    (state: any) => state?.Comman
  );
  const [isOpen, setIsOpen] = useState(window.innerWidth > 600);
  const [isHidden, setIsHidden] = useState(window.innerWidth < 400);
  const { isLogin, setIsLogin } = useContext(MyContext);
  const userRoleId = localStorage.getItem("userRoleId");
  const userOnboarded = localStorage.getItem("userOnboarded");

  const size = window.innerWidth;

  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const loginToken = localStorage.getItem(UserLoginData.token);

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(window.innerWidth > 600);
      setIsHidden(window.innerWidth < 400);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const currentLink = window.location.href;

    if (currentLink.includes("localhost")) {
      OpenAPI.BASE = BASE_API_URL;
    }
    setIsLogin(loginToken);
    OpenAPI.TOKEN = loginToken;

  }, []);

  useEffect(() => {
    // Add event listener for clicks outside the menu
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const handleClickOutside = (event) => {
    const size = window.innerWidth;

    if (menuRef.current && !menuRef.current.contains(event.target)) {
      // Clicked outside of the menu, so collapse it

      if (size < 400) {
        setIsHidden(true);
      } else if (size < 600) {
        setIsOpen(false);
      }
    }
  };

  return (
    <>
      {isLogin && isLogin ? (
        <>
          {userOnboarded === "true" && (
            <CustomTopBar
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              isHidden={isHidden}
              setIsHidden={setIsHidden}
              size={size}
            />
          )}

          <div className="menuBarContainer" style={{ display: "flex" }}>
            {userOnboarded === "true" && (
              <div
                ref={menuRef}
                className={ isHidden ? "hiddenMenu" : isOpen ? "openedMenu" : "closedMenu"}
              >
                <CustomMenu
                  isHidden={isHidden}
                  setIsHidden={setIsHidden}
                  setIsOpen={setIsOpen}
                  isOpen={isOpen}
                  size={size}
                />
              </div>
            )}
            <div
              className={
                userOnboarded === "false"
                  ? "hiddenMenuRightSide"
                  : isOpen
                  ? "openedMenuRightSide"
                  : "closeMenuRightSide"
              }
            >
              <Routes>
                <Route
                  path={URLS.USER_DASHBOARD}
                  element={<UserDashboard userRoleId={parseInt(userRoleId)} />}
                />
                <Route path="/callback/gmail" element={<GmailAuthReturn />} />
                <Route
                  path={MAIN_ROUTES_URLS.HOME}
                  element={<UserDashboard userRoleId={parseInt(userRoleId)} />}
                />
                <Route
                  path={URLS.QUESTIONNAIRE}
                  element={<NewQuestionnaire />}
                />
              </Routes>
              {showSuccessAlert && (
                <SuccessAlert
                  message={successMsg}
                  setShowSuccessAlert={(value: boolean) =>
                    dispatch(setShowSuccessAlert(value))
                  }
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <Routes>
          <Route path={MAIN_ROUTES_URLS.HOME} element={<Login />} />
          <Route
            path={URLS.RESET_PASSWORD_BY_USERKEY}
            element={<ResetPassword />}
          />
          <Route path={URLS.FORGET_PASSWORD} element={<ForgetPassword />} />
          <Route path={URLS.CHECK_MAIL} element={<CheckEmail />} />
          <Route path={URLS.SIGN_UP} element={<Signup />} />
          {/* <Route
            path={URLS.SIGNUP_AFTER_GOOGLE}
            element={<SignupAfterGoogle />}
          /> */}
        </Routes>
      )}
    </>
  );
}
