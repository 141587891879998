import React, { useState, useEffect, useRef } from 'react'
import useWindowDimensions from '../../../hooks/windowDimensionsHook';
import './topFixedContainer.css';
import Search from '../search';
import AddButton from '../../firstTimeUserExperience/commonCom/AddButton';
import { useNavigate } from 'react-router';

const TopFixedBar = ({ rowCount,
   rowCountLabel, 
   searchPlaceholder,
   addButtonLabel,
   searchValue,
   setSearchValue, 
   navigateTo = null,
   addCallback = null}) => {

 const navigate = useNavigate();

  return (
    <>
      <div className="listViewTopHeader">
        <p className="rowCountLabel"> {rowCount} {rowCountLabel}</p>
        <div className="topBarLeft">
          <div>
          <Search 
            placeholderName={searchPlaceholder}
            searchValue={searchValue}
            searchFilterChange={setSearchValue}
          />
          </div>
          <AddButton
            onClick={() => navigateTo ? navigate(navigateTo) : addCallback()}
            bname={addButtonLabel}
          />
        </div>
      </div>
      </>
  );
};

export default TopFixedBar;
