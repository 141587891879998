// import React, { useEffect, useState } from "react";
// import { PaymentService } from "@propertelligent/client-api";
// import { Offcanvas } from "react-bootstrap";
// import EmptyData from "../../common/EmptyData";
// import { getKeyByValu } from "./helper";
// import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";
// import PayForm from "../../common/Payment/PayForm";
// import DateHelper from "../../../utils/DateHelper";
// import SuccessAlert from "../../tenants/SuccessAlert";
// import { GetImages } from "../../../utils/GetImages";

// const Transactions = ({
//   sorting,
//   rows,
//   paymentMethodsArr,
//   fetchData,
//   fetchAndFormatExpensebycategory,
// }) => {
//   const [showModel, setShowModel] = useState(false);
//   const [dueAccountId, setDueAccountId] = useState(-1);
//   const [showSuccessAlert, setShowSuccessAlert] = useState(false);
//   useEffect(() => {}, [rows?.length]);

//   const sortedTransactions = rows.sort(
//     (a, b) => new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime()
//   );
//   const recentFiveTransactions = sortedTransactions.slice(0, 5);

//   const handleSubmitPayPaymentDueAccount = (values: any) => {
//     if (dueAccountId > 0) {
//       Object.assign(values, {
//         payDueAccountId: dueAccountId,
//       });

//       PaymentService.putApiPaymentDueAccountPay(values)
//         .then((res: any) => {
//           if (res?.success) {
//             setShowModel(false);
//             setShowSuccessAlert(true);
//             setTimeout(() => {
//               setShowSuccessAlert(false);
//             }, 3000);
//             fetchData();
//             fetchAndFormatExpensebycategory();
//           }
//         })
//         .catch((error) => {});
//     }
//   };
//   return (
//     <>
//       {recentFiveTransactions?.length === 0 ? (
//         <>
//           <EmptyData
//             mainText={undefined}
//             subText="Data Not Available"
//             button={undefined}
//           />
//         </>
//       ) : sorting === "last-five" ? (
//         recentFiveTransactions?.map((item, index) => (
//           <div
//             key={item.id}
//             className={
//               index !== recentFiveTransactions?.length - 1
//                 ? `transactionRow BorderBottom`
//                 : `transactionRow`
//             }
//           >
//             <div className="firstbox">
//               {item.isDueToOrganization == true ? (
//                 <div className="CommonRoundBackground BackgroundlightGreen Icon40">
//                   <img src={GetImages.GreenArrowOutward} />
//                 </div>
//               ) : (
//                 <div className="CommonRoundBackground BackgroundlightRed Icon40">
//                   <img src={GetImages.RedArrowOutward} />
//                 </div>
//               )}

//               <div className="commonbox">
//                 <p className="FW500_LH20_sub_text">{item.description}</p>
//                 <p className="FW400_LH16_small_text">
//                   {item.payerPayeeName}
//                 </p>
//               </div>
//             </div>
//             <div className="leftcommonbox">
//               <div className="d-flex align-items-center gap-1">
//                 <p className="FW700_LH24_main_text "> $ {item.formatted.AmountRaw}</p>
//                 {getKeyByValu(item?.paymentDueAccountStatusId) === "Due" ? (
//                   <AddTraspButton
//                     bname={item?.isDueToOrganization ? "Pay" : "Send Reminder"}
//                     iconPosition={undefined}
//                     imageValue={undefined}
//                     onClick={() => {
//                       setShowModel(true);
//                       setDueAccountId(item.id);
//                     }}
//                     border={true}
//                   />
//                 ) : (
//                   ""
//                 )}
//               </div>
//               <p className="FW400_LH16_small_text">
//                 {item.formatted.DueDateDateOnly}
//               </p>
//             </div>
//           </div>
//         ))
//       ) : (
//         rows?.map((item) => (
//           <div key={item.id} className="transactionRow">
//             <div className="firstbox">
//               <div className="imgcommonstyle">
//                 <img
//                   src={GetImages.incomingRentIconGreen}
//                   className="w-100 h-100"
//                 />
//               </div>
//               <div className="commonbox">
//                 <p className="FW500_LH20_sub_text">{item.description}</p>
//                 <p className="FW400_LH16_small_text">
//                   {item.payerPayeeName}
//                 </p>
//               </div>
//             </div>
//             <div className="leftcommonbox">
//               <div className="d-flex align-items-center">
//                 <p className="FW700_LH24_main_text"> ${item.amount}</p>
//               </div>
//               <p className="FW400_LH16_small_text">{ DateHelper.convertUTCtoDateTime(item.dueDate) }</p>
//             </div>
//           </div>
//         ))
//       )}
//       <Offcanvas
//         className="offcanvas"
//         show={showModel}
//         onHide={() => setShowModel(false)}
//         placement={"end"}
//         scroll={true}
//       >
//         <Offcanvas.Header className="canvasHeader" closeButton>
//           <Offcanvas.Title>Add Payment</Offcanvas.Title>
//         </Offcanvas.Header>
//         <Offcanvas.Body>
//           <PayForm
//             paymentMethodsArr={paymentMethodsArr}
//             onClose={() => setShowModel(false)}
//             onConfirm={(values: any) =>
//               handleSubmitPayPaymentDueAccount(values)
//             }
//           />
//         </Offcanvas.Body>
//       </Offcanvas>

//       {showSuccessAlert && (
//         <SuccessAlert
//           message={"Payment Added Successfully"}
//           setShowSuccessAlert={setShowSuccessAlert}
//         />
//       )}
//     </>
//   );
// };

// export default Transactions;

import React, { useEffect, useState } from "react";
import { PaymentService } from "@propertelligent/client-api";
import { Offcanvas } from "react-bootstrap";
import EmptyData from "../../common/EmptyData";
import { getKeyByValu } from "./helper";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";
import PayForm from "../../common/Payment/PayForm";
import DateHelper from "../../../utils/DateHelper";
import SuccessAlert from "../../tenants/SuccessAlert";
import { GetImages } from "../../../utils/GetImages";

const Transactions = ({
  sorting,
  rows,
  paymentMethodsArr,
  fetchData,
  fetchAndFormatExpensebycategory,
}) => {
  const [showModel, setShowModel] = useState(false);
  const [dueAccountId, setDueAccountId] = useState(-1);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  useEffect(() => {}, [rows?.length]);

  const sortedTransactions = rows?.sort(
    (a, b) => new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime()
  );
  const recentFiveTransactions = sortedTransactions?.slice(0, 5);

  const handleSubmitPayPaymentDueAccount = (values: any) => {
    if (dueAccountId > 0) {
      Object.assign(values, {
        payDueAccountId: dueAccountId,
      });

      PaymentService.putApiPaymentDueAccountPay(values)
        .then((res: any) => {
          if (res?.success) {
            setShowModel(false);
            setShowSuccessAlert(true);
            setTimeout(() => {
              setShowSuccessAlert(false);
            }, 3000);
            fetchData();
            fetchAndFormatExpensebycategory();
          }
        })
        .catch((error) => {});
    }
  };
  return (
    <>
      {recentFiveTransactions?.length === 0 ? (
        <>
          <EmptyData
            mainText={undefined}
            subText="Data Not Available"
            button={undefined}
          />
        </>
      ) : sorting === "last-five" ? (
        recentFiveTransactions?.map((item, index) => (
          <div
            key={item.id}
            className={
              index !== recentFiveTransactions?.length - 1
                ? `transactionRow BorderBottom`
                : `transactionRow`
            }
          >
            <div className="firstbox">
              {item.isDueToOrganization == true ? (
                <div className="CommonRoundBackground BackgroundlightGreen Icon40">
                  <img src={GetImages.GreenArrowOutward} />
                </div>
              ) : (
                <div className="CommonRoundBackground BackgroundlightRed Icon40">
                  <img src={GetImages.RedArrowOutward} />
                </div>
              )}

              <div className="commonbox">
                <p className="FW500_LH20_sub_text">{item.description}</p>
                <p className="FW400_LH16_small_text">{item.payerPayeeName}</p>
              </div>
            </div>
            <div className="leftcommonbox">
              <div className="d-flex align-items-center gap-1">
                <p className="FW700_LH24_main_text ">
                  {" "}
                  $ {item.formatted.AmountRaw}
                </p>
                {getKeyByValu(item?.paymentDueAccountStatusId) === "Due" ? (
                  <AddTraspButton
                    bname={item?.isDueToOrganization ? "Pay" : "Send Reminder"}
                    iconPosition={undefined}
                    imageValue={undefined}
                    onClick={() => {
                      setShowModel(true);
                      setDueAccountId(item.id);
                    }}
                    border={true}
                  />
                ) : (
                  ""
                )}
              </div>
              <p className="FW400_LH16_small_text">
                {item.formatted.DueDateDateOnly}
              </p>
            </div>
          </div>
        ))
      ) : (
        rows?.map((item) => (
          <div key={item.id} className="transactionRow">
            <div className="firstbox">
              <div className="imgcommonstyle">
                <img
                  src={GetImages.incomingRentIconGreen}
                  className="w-100 h-100"
                />
              </div>
              <div className="commonbox">
                <p className="FW500_LH20_sub_text">{item.description}</p>
                <p className="FW400_LH16_small_text">{item.payerPayeeName}</p>
              </div>
            </div>
            <div className="leftcommonbox">
              <div className="d-flex align-items-center">
                <p className="FW700_LH24_main_text"> ${item.amount}</p>
              </div>
              <p className="FW400_LH16_small_text">
                {DateHelper.convertUTCtoDateTime(item.dueDate)}
              </p>
            </div>
          </div>
        ))
      )}
      <Offcanvas
        className="offcanvas"
        show={showModel}
        onHide={() => setShowModel(false)}
        placement={"end"}
        scroll={true}
      >
        <Offcanvas.Header className="canvasHeader" closeButton>
          <Offcanvas.Title>Add Payment</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <PayForm
            paymentMethodsArr={paymentMethodsArr}
            onClose={() => setShowModel(false)}
            onConfirm={(values: any) =>
              handleSubmitPayPaymentDueAccount(values)
            }
          />
        </Offcanvas.Body>
      </Offcanvas>

      {showSuccessAlert && (
        <SuccessAlert
          message={"Payment Added Successfully"}
          setShowSuccessAlert={setShowSuccessAlert}
        />
      )}
    </>
  );
};

export default Transactions;
