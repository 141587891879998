import { useEffect, useMemo, useRef, useState } from "react";
import { GetImages } from "../../utils/GetImages";
const Search = ({ placeholderName, searchValue, searchFilterChange }) => {
  const inputEl = useRef(null);
   
  useEffect(() => {
    inputEl.current.value = searchValue;
    inputEl.current.focus();
  }, [searchValue]);

  let changeTimeout;

  function onChange(e){
    window.clearTimeout(changeTimeout);
    changeTimeout = window.setTimeout(() => {
      window.clearTimeout(changeTimeout);
      searchFilterChange(e.target.value)
    }, 500);
  }

  return (
    <>
      <div className="searchInputContainer">
        <img src={GetImages.Search} alt="serchSVG" />
        <input        
          ref={inputEl}
          className="serchInput FW400_LH16_small_text"
          onChange={onChange}
          placeholder={placeholderName}
        />
      </div>
    </>
  );
};

export default Search;
