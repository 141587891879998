// import React from "react";
// import { useNavigate } from "react-router";
// import ProgressBar from "../../common/ProgressBar";
// import StatusTag from "../../common/StatusTag";
// import { AvatarWithLoading } from "../../common/avator";
// import { UnitTypesEnum } from "@propertelligent/client-api";
// import { Blackbed, Blackshower, Blackvector } from "../../../assets";
// import { URLS, getBathroomText } from "../../../constants/constants";
// import { getSizeString } from "../../../utils/helper";
// import RedArrow from "../../../assets/redArrow.png";

// const CardView = (data: any) => {
//   const navigate = useNavigate();
//   const showTarget = true;

//   function getKeyByValue(value: number): string | undefined {
//     const keys = Object.keys(UnitTypesEnum).filter(
//       (key) => UnitTypesEnum[key as keyof typeof UnitTypesEnum] === value
//     );
//     return keys.length > 0 ? keys[0] : undefined;
//   }

//   const handleItemClick = (id: string | undefined) => {
//     navigate(`${URLS.EDIT_UNIT}/${id}`);
//   };

//   const handleInviteTenantClick = (
//     event: React.MouseEvent<HTMLParagraphElement>
//   ) => {
//     event.stopPropagation();
//     navigate(URLS.CREATE_TENANT);
//   };


//   return (
//     <>
//       <div key={data?.item?.id}
//         className={"flexFullRow itemContainer"}
//         onClick={() => handleItemClick(data?.item?.id)}
//       >
//         <div className="flexColumn">
//           <div className="flexFullRow">
//             <div className="flexColumn itemContainerimg">
//               <AvatarWithLoading
//                 docNumber={data?.item?.mainImageId}
//                 avatar={true}
//                 Size="Icon40"
//               />
//             </div>
//             <div className="flexColumn itemContainerimgDetail">
//               <div className="flexFullRow title">
//                 <span style={{ marginRight: 5 }}>{data?.item?.unitNumber}</span>
//                 {data?.item?.tenantNames == null ?
//                   <StatusTag color={"gray"} statusText={"Vacant"} isLarge={true} /> : <></>}
//                 {data?.item?.expensesDue > 0 ?
//                   <StatusTag
//                     color={"red"} icon={<img
//                       src={RedArrow}
//                     />} isLarge={false}
//                     statusText={data?.item?.formatted?.ExpensesDue ? `$ ${data?.item?.formatted?.ExpensesDue} due ` : ""}
//                   /> : <></>}
//               </div>
//               <div className="flexFullRow">
//                 <span className="featureType">
//                   {data?.item?.property?.address?.streetAddress || data?.item?.property?.name}
//                 </span>
//               </div>
//               <div className="flexFullRow">
//                 <span className="featureType">
//                   {getKeyByValue(data?.item?.unitTypeId)}{" "}
//                   {data?.item?.tenantNames?.[0] && (
//                     <span>{data?.item?.tenantNames?.[0]}</span>
//                   )}
//                 </span>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* End of first column */}
//         <div className="unitNumberOfItem">
//           {data?.item?.unitTypeId !== 3 ? (
//             <>
//               <div className="feature">
//                 <img src={Blackbed} />
//                 <span className="overview-small-text">
//                   {data?.item?.numberOfBedrooms}{" "}
//                   {getBathroomText(data?.item?.numberOfBedrooms, "Bedroom")}
//                 </span>
//               </div>
//               <div className="dividerdiv"></div>
//               <div className="feature">
//                 <img src={Blackshower} />
//                 <span className="overview-small-text">
//                   {data?.item?.numberOfBathrooms}{" "}
//                   {getBathroomText(data?.item?.numberOfBathrooms, "Bathroom")}
//                 </span>
//               </div>
//             </>
//           ) : (
//             <div className="feature">
//               <img src={Blackshower} />
//               <span className="overview-small-text">
//                 {data?.item?.isBathAttached ? "Attach" : "Studio"}
//               </span>
//             </div>
//           )}
//           <div className="dividerdiv"></div>
//           <div className="feature">
//             <img src={Blackvector} />
//             <span className="overview-small-text">
//               {" "}
//               {getSizeString(data?.item?.size)}
//             </span>
//           </div>
//         </div>
//         {/* End of 2nd column */}
//         {showTarget &&
//           <div className="unitListProgress" style={{ width: "20%" }}>
//             {data?.item?.leaseRemainingMonths !== null ? (
//               <ProgressBar monthsRemaining={data?.item?.leaseRemainingMonths} leaseType={data?.item?.formatted?.LeaseTypeId} /> //     <Box className="rightSideContainer">
//             ) : (
//               <div className="inviteTenant">
//                 <p onClick={handleInviteTenantClick}> + Add Tenant</p>
//               </div>
//             )}
//           </div>
//         }
//       </div>
//     </>
//   );
// };

// export default CardView;

import React from "react";
import { useNavigate } from "react-router";
import ProgressBar from "../../common/ProgressBar";
import StatusTag from "../../common/StatusTag";
import { AvatarWithLoading } from "../../common/avator";
import { UnitTypesEnum } from "@propertelligent/client-api";
import { Blackbed, Blackshower, Blackvector } from "../../../assets";
import { URLS, getBathroomText } from "../../../constants/constants";
import { getSizeString } from "../../../utils/helper";
import RedArrow from "../../../assets/redArrow.png";

const CardView = (data: any) => {
  const navigate = useNavigate();
  const showTarget = true;

  function getKeyByValue(value: number): string | undefined {
    const keys = Object.keys(UnitTypesEnum).filter(
      (key) => UnitTypesEnum[key as keyof typeof UnitTypesEnum] === value
    );
    return keys.length > 0 ? keys[0] : undefined;
  }

  const handleItemClick = (id: string | undefined) => {
    navigate(`${URLS.EDIT_UNIT}/${id}`);
  };

  const handleInviteTenantClick = (
    event: React.MouseEvent<HTMLParagraphElement>
  ) => {
    event.stopPropagation();
    navigate(URLS.CREATE_TENANT);
  };

  return (
    <>
      <div
        key={data?.item?.id}
        className={"flexFullRow itemContainer"}
        onClick={() => handleItemClick(data?.item?.id)}
      >
        <div className="flexColumn">
          <div className="flexFullRow">
            <div className="flexColumn itemContainerimg">
              <AvatarWithLoading
                docImage={data.item?.mainImageBase64}
                docNumber={data?.item?.mainImageId}
                avatar={true}
                Size="Icon40"
              />
            </div>
            <div className="flexColumn itemContainerimgDetail">
              <div className="flexFullRow title">
                <span style={{ marginRight: 5 }}>{data?.item?.unitNumber}</span>
                {data?.item?.tenantNames == null ? (
                  <StatusTag
                    color={"gray"}
                    statusText={"Vacant"}
                    isLarge={true}
                  />
                ) : (
                  <></>
                )}
                {data?.item?.expensesDue > 0 ? (
                  <StatusTag
                    color={"red"}
                    icon={<img src={RedArrow} />}
                    isLarge={false}
                    statusText={
                      data?.item?.formatted?.ExpensesDue
                        ? `$ ${data?.item?.formatted?.ExpensesDue} due `
                        : ""
                    }
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="flexFullRow">
                <span className="featureType">
                  {data?.item?.property?.address?.streetAddress ||
                    data?.item?.property?.name}
                </span>
              </div>
              <div className="flexFullRow">
                <span className="featureType">
                  {getKeyByValue(data?.item?.unitTypeId)}{" "}
                  {data?.item?.tenantNames?.[0] && (
                    <span>{data?.item?.tenantNames?.[0]}</span>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* End of first column */}
        <div className="unitNumberOfItem">
          {data?.item?.unitTypeId !== 3 ? (
            <>
              <div className="feature">
                <img src={Blackbed} />
                <span className="overview-small-text">
                  {data?.item?.numberOfBedrooms}{" "}
                  {getBathroomText(data?.item?.numberOfBedrooms, "Bedroom")}
                </span>
              </div>
              <div className="dividerdiv"></div>
              <div className="feature">
                <img src={Blackshower} />
                <span className="overview-small-text">
                  {data?.item?.numberOfBathrooms}{" "}
                  {getBathroomText(data?.item?.numberOfBathrooms, "Bathroom")}
                </span>
              </div>
            </>
          ) : (
            <div className="feature">
              <img src={Blackshower} />
              <span className="overview-small-text">
                {data?.item?.isBathAttached ? "Attach" : "Studio"}
              </span>
            </div>
          )}
          <div className="dividerdiv"></div>
          <div className="feature">
            <img src={Blackvector} />
            <span className="overview-small-text">
              {" "}
              {getSizeString(data?.item?.size)}
            </span>
          </div>
        </div>
        {/* End of 2nd column */}
        {showTarget && (
          <div className="unitListProgress" style={{ width: "20%" }}>
            {data?.item?.leaseRemainingMonths !== null ? (
              <ProgressBar
                monthsRemaining={data?.item?.leaseRemainingMonths}
                leaseType={data?.item?.formatted?.LeaseTypeId}
              /> //     <Box className="rightSideContainer">
            ) : (
              <div className="inviteTenant">
                <p onClick={handleInviteTenantClick}> + Add Tenant</p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CardView;

