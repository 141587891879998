import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Offcanvas, Spinner } from "react-bootstrap";
import OpenActionComponent from "./OpenActionComponent";

import {
  AnalyticsService,
  DocumentService,
  EntityType,
  NoteService,
  NotesEntityTypesEnum,
  TenantService,
  UnitService,
  PaymentService,
  OpenItemService,
} from "@propertelligent/client-api";
import EditTenentForm from "./EditTenantForm";
import SingleNoteComponent from "./SingleNoteComponent";
import UploadDocumentModal from "./UploadDocumentModal";
import SuccessAlert from "./SuccessAlert";
import { UserLoginData } from "../common/charts/Enums";
import NameAvatarComponent from "./NameAvatarComponent";
import { convertStreamAndDownload } from "../../utils/helper";
import { GetImages } from "../../utils/GetImages";
import AddTraspButton from "../firstTimeUserExperience/commonCom/AddTraspButton";
import { usePaginationHook } from "../../hooks/usePaginationHook";
import PaymentForm from "../common/Payment/PaymentForm";
import Transactions from "../properties/components/transactions";
import DateHelper from "../../utils/DateHelper";
import ScrollingContainer from "../common/scrollingContainer/ScrollingContainer";
import { URLS } from "../../constants/constants";

const NewSingleTenantView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [userData, setUserData] = useState<any>(null);
  const [notesData, setNotesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noteText, setNoteText] = useState("");
  const [noteError, setNoteError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [totalRevenue, setTotalRevenue] = useState("");
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [leaseId, setLeaseId] = useState(null);

  const [alertMessage, setAlertMessage] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [openActionData, setOpenActionData] = useState([]);

  const [payeeAccountsArr, setPayeeAccountsArr] = useState([]);
  const [paymentMethodsArr, setPaymentMethodsArr] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loadingStates, setLoadingStates] = useState({
    tenantData: true,
    notesData: true,
    revenueData: true,
    transactionDataLoading: true,
  }); // Loading state
  useEffect(() => {
    getTenantsData();
    getAllNotes();
    getTotalRevenue();
    // getOpenActionItemsData();
  }, []);

  useEffect(() => {
    getTenantsData();
  }, [showModal, showDocumentModal]);

  // const getOpenActionItemsData = async () => {
  //   OpenItemService.getApiOpenItemsTenantlist(Number(id))
  //     .then((res) => setOpenActionData(res?.success))
  //     .catch((err) => {});
  // };

  const getTenantsData = async () => {
    const tenantsResponse = await TenantService.getApiTenant1(Number(id));

    if (tenantsResponse?.success?.unitId) {
      setLeaseId(tenantsResponse?.success?.currentLeaseInfo?.leaseDocumentId);
      const unitResponse = await UnitService.getApiUnit1(
        tenantsResponse?.success?.unitId
      );
      if (unitResponse?.success) {
        setUserData({
          unitData: unitResponse.success,
          ...tenantsResponse.success,
        });

        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          tenantData: false,
        }));
      }
    }
  };

  const downloadDocument = (id, fileName) => {
    setIsUploading(true);

    DocumentService.getApiDocumentBase641(id)
      .then((docRes) => {
        //Conversion alogorithm
        convertStreamAndDownload(docRes?.success, fileName);
        setIsUploading(false);
      })
      .catch((docErr) => {
        setIsUploading(false);
      });
  };

  const addNoteHandler = () => {
    if (noteText.trim() == "") {
      setNoteError(true);
    } else {
      const noteObject = {
        noteData: noteText,
        entityId: Number(id),
        entityTypeId: NotesEntityTypesEnum.Tenant,
        modifiedBy: localStorage.getItem(UserLoginData.user_name),
      };

      NoteService.postApiNote(noteObject)
        .then((res) => {
          setNoteText("");
          getAllNotes();
          setAlertMessage("Note posted Successfully");
          setShowSuccessAlert(true);
          setTimeout(() => {
            setShowSuccessAlert(false);
          }, 3000);
        })
        .catch((err) => {});
    }
  };

  const getAllNotes = async () => {
    const notesResponse = await NoteService.getApiNoteTenantlist(Number(id));
    if (notesResponse) {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        notesData: false,
      }));
      setNotesData(notesResponse.success);
    }
  };

  const getTotalRevenue = async () => {
    const cashFlowResponse =
      await AnalyticsService.getApiAnalyticsTenantCashflow(Number(id));

    if (cashFlowResponse.success) {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        revenueData: false,
      }));
      let allIncome = cashFlowResponse.success;
      const totalIncome = allIncome.reduce(
        (sum, entry) => sum + entry.income,
        0
      );
      setTotalRevenue(totalIncome.toLocaleString());
    }
  };

  const handleClose = () => setShowDocumentModal(false);
  const handleShow = () => setShowDocumentModal(true);

  const {
    rows,
    fetchData,
    loading: transactionDataLoading,
  } = usePaginationHook({
    httpFetchDataFn: PaymentService.getApiPaymentDueAccountTenantlist,
    paginationDataMapper: null,
    parentEntityId: Number(id),
    isDependentEntity: true,
    FullyQualifiedFilters: null,
  });

  useEffect(() => {
    const orgId = parseInt(localStorage.getItem(UserLoginData.organization_id));
    const tenantId = parseInt(id);

    PaymentService.getApiPaymentMethodTenantlist(tenantId).then((res) => {
      if (res.success) {
        setPaymentMethodsArr(res.success);
      }
    });

    PaymentService.getApiPayeeAccountOrganizationlist(orgId)
      .then((ptRes) => {
        if (ptRes?.success) {
          setPayeeAccountsArr(ptRes?.success);
        }
      })
      .catch((ptErr) => {});
  }, [id]);

  const handleSubmitPaymentAccountRecord = ({ values, payvalues }) => {
    const {
      description,
      dueDate,
      amount,
      entityId,
      payeeAccountId,
      gracePeriodInDays,
      paymentDueAccountStatusId,
      isRecordPayment,
      paymentTypeId,
    } = values;
    const { fromPaymentMethodId } = payvalues;

    const updatedValues = {
      amount,
      description,
      dueDate: new Date(dueDate).toISOString(),
      entityId,
      entityTypeId: EntityType.Tenant,
      payeeAccountId,
      gracePeriodInDays,
      paymentDueAccountStatusId,
      fromPaymentMethodId,
      isRecordPayment,
      paymentTypeId,
    };

    PaymentService.postApiPaymentDueAccount(updatedValues)
      .then((res: any) => {
        if (res?.success) {
          setShowModel(false);

          setShowSuccess(true);
          setTimeout(() => {
            setShowSuccess(false);
          }, 3000);

          setTimeout(() => {
            navigate(URLS.TENANTS);
          }, 2000);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (!transactionDataLoading) {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        transactionDataLoading: false,
      }));
    }
  }, [transactionDataLoading]);

  useEffect(() => {
    if (
      !loadingStates.notesData &&
      !loadingStates.revenueData &&
      !loadingStates.tenantData &&
      !loadingStates.transactionDataLoading
    ) {
      setLoading(false);
    }
  }, [loadingStates]);

  return (
    // <ScrollingContainer Content={() => {return (
    <>
      {loading && ( // Check loading state
        <div className="loading-container">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}

      <div
        style={{ display: loading == true && "none" }}
        className="singleTenantView"
      >
      <div className="sticky-tenant-topbar">
      <div className="singleTenantTopbar">
          <div
            onClick={() => {
              navigate("/tenants");
            }}
            className="clickable"
          >
            <p className="singleTenantTopbarHeading">Tenant</p>
          </div>
          <img src={GetImages.BlackChevronRight} />
          <p className="singleTenantTopbarSubHeading">Tenant Detail</p>
        </div>

        <div className="newNameSectionContainer">
          <div className="newSingleTenantSubTopBar d-flex align-items-center justify-content-between">
            <div className="d-flex newLeftSideBox">
              {/* <img height={48} width={48} src={profilePic} /> */}
              <NameAvatarComponent
                firstName={userData?.firstName}
                lastName={userData?.lastName}
                heightWidth={48}
              />
              <div>
                <div className="d-flex align-items-center gap-1">
                  <p className="newSingleTenantName">
                    {userData && `${userData.firstName} ${userData.lastName}`}
                  </p>

                  {userData?.expensesDue > 0 && (
                    <div className="dueRedBox">
                      <div>
                        <img src={GetImages.redArrow} />
                      </div>
                      <p className="dueRedBoxText">{`$1500 due`}</p>
                    </div>
                  )}
                </div>
                <div className="d-flex align-items-center">
                  <img height={16} width={16} src={GetImages.time}></img>
                  <p className="sinceTimePeriod">
                    {userData !== null && userData?.tenancySince !== null
                      ? userData?.tenancySince
                      : "Since 0 months"}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center gap-3">
              <p
                className="newEditTanentButton clickable"
                onClick={() => setShowModal(true)}
              >
                Edit Tenant
              </p>
              <AddTraspButton
                onClick={() => setShowModel(true)}
                bname="Add Payment"
                iconPosition="right"
                border={true}
              />
              <Offcanvas
                className="offcanvas"
                show={showModel}
                onHide={() => setShowModel(false)}
                placement={"end"}
                scroll={true}
              >
                <Offcanvas.Header className="canvasHeader" closeButton>
                  <Offcanvas.Title>Add Payment</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <PaymentForm
                    showModel={showModel}
                    entityId={id && Number(id)}
                    payeeAccountsArr={payeeAccountsArr}
                    paymentMethodsArr={paymentMethodsArr}
                    onClose={() => setShowModel(false)}
                    onConfirm={(values: any) =>
                      handleSubmitPaymentAccountRecord(values)
                    }
                    title={"Add Entity Due Payment"}
                    amt={null}
                  />
                </Offcanvas.Body>
              </Offcanvas>
            </div>
          </div>

          <div className="newPersonalInfoBox d-flex justify-content-between flex-wrap gap-2">
            <div>
              <div className="newPersonalInfoBoxItem mb-2 d-flex justify-content-start">
                <img width={18} height={18} src={GetImages.homeIconOrange} />
                {userData && (
                  <p>
                    {`${
                      userData?.unitData?.property?.address?.streetAddress ||
                      userData?.unitData?.property?.name
                    } ${userData?.unitData?.property?.address?.city} ${
                      userData?.unitData?.property?.address?.state
                    } ${userData?.unitData?.property?.address?.zip}`}
                  </p>
                )}
              </div>

              <div className="newPersonalInfoBoxItem  d-flex justify-content-start">
                <img width={18} height={18} src={GetImages.OrangeUnit} />
                <p>
                  {userData &&
                    `${userData?.unitNumber} ${userData?.unitData?.formatted?.UnitTypeId} `}
                </p>
              </div>
            </div>
            <div className="seperatorLine"></div>
            <div>
              <div className="newPersonalInfoBoxItem mb-2 d-flex justify-content-start">
                <img width={18} height={18} src={GetImages.mailIconOrange} />
                <p>{userData && userData.email}</p>
              </div>

              <div className="newPersonalInfoBoxItem d-flex justify-content-start">
                <img width={18} height={18} src={GetImages.phoneIconOrange} />
                <p>{userData && userData.phoneNumber}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="newDocumentSection d-flex gap-2">
          <div
            className="newDocumentItem clickable"
            onClick={() => {
              downloadDocument(leaseId, "Lease Agreement");
            }}
          >
            {isUploading ? (
              <div className="d-flex justify-content-center align-items-center w-100">
                <img height={40} src={GetImages.loadingGif} />
              </div>
            ) : (
              <div className="d-flex align-items-center gap-3 p-2">
                <img src={GetImages.pdfImage} />
                <p className="newDocumentItemText">Lease Agreement</p>
              </div>
            )}
          </div>

          <div onClick={handleShow} className="newDocumentUploadDiv clickable">
            <img src={GetImages.upload} />
          </div>
        </div>
      </div>

        {/* <div className="openActionBox">
          <div className="actionBoxBar">
            <p className="actionBoxBarHeading">Open Action Items</p>
          </div>
          <div className="openActionContainer ">
            {openActionData &&
              openActionData.map((item) => {
                return (
                  <OpenActionComponent data={item} type={item.openItemType} />
                );
              })}
          </div>
        </div> */}

       <div className="bottom-tenant-scrolling">
       <div className="revenueMonthsBox d-flex flex-wrap">
          <div className="revenueSingleContainer d-flex justify-content-start gap-3">
            <img width={40} height={40} src={GetImages.dollarAvatar} />
            <div className="innerBoxRevenue">
              <p className="openActionAmount d-flex align-items-start">
                {`$${totalRevenue}`}
              </p>
              <p className="openActionDate d-flex align-items-start">
                Revenue till date
              </p>
            </div>
          </div>
          <div className="revenueSingleContainer d-flex justify-content-start gap-3">
            <img width={40} height={40} src={GetImages.vacant} />

            {userData?.leaseRemainingMonths !== null ? (
              <div className="innerBoxRevenue">
                <p className="openActionAmount d-flex align-items-start">
                  {userData &&
                    userData?.leaseRemainingMonths &&
                    `${userData?.leaseRemainingMonths} Months`}
                </p>
                <p className="openActionDate d-flex align-items-start">
                  Left in lease
                </p>
              </div>
            ) : (
              <div className="d-flex align-items-center justify-content-center">
                <p className="openActionDate">Invitation pending</p>
              </div>
            )}
          </div>
        </div>

        <div className="ledgerNotesSectionContainer d-flex flex-wrap">
          <div className="ledgerNotesSection">
            <p className="ledgerNotesHeading">Last 5 Transactions</p>
            {/* <RentLedgerContainer id={Number(id)} /> */}
            <div className="rentLedgerContainer p-3">
              <Transactions
                rows={rows}
                sorting="last-five"
                paymentMethodsArr={paymentMethodsArr}
                fetchData={fetchData}
                fetchAndFormatExpensebycategory={undefined}
              />
            </div>
          </div>
          <div className="ledgerNotesSection">
            <p className="ledgerNotesHeading">Notes</p>
            <div className="rentLedgerContainer">
              <div className="notesInput d-flex justify-content-between align-items-center p-3">
                <div className="inputCommentContainer">
                  <input
                    value={noteText}
                    onChange={(e) => {
                      setNoteText(e.target.value);
                      setNoteError(false);
                    }}
                    className="messageInput"
                    placeholder="Type your notes here..."
                  />
                  <p
                    onClick={addNoteHandler}
                    className="commentText clickable "
                  >
                    Add Note
                  </p>
                </div>
              </div>
              {noteError && <p className="noteError">Note cannot be empty</p>}
              <div className="messageContainer px-3">
                {notesData.length > 0 ? (
                  notesData.map((item, i) => {
                    return (
                      <SingleNoteComponent
                        date={DateHelper.convertUTCtoDateTimeSimple(
                          item?.modified,
                          true
                        )}
                        noteData={item.noteData}
                        key={i}
                        name={item.modifiedBy}
                      />
                    );
                  })
                ) : (
                  <div className="nothingToShowText">
                    Notes history is empty
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {showSuccessAlert && (
          <SuccessAlert
            message={alertMessage}
            setShowSuccessAlert={setShowSuccessAlert}
          />
        )}
        {showSuccessAlert && (
          <SuccessAlert
            message={"Payment Added Successfully"}
            setShowSuccessAlert={setShowSuccessAlert}
          />
        )}

        <Offcanvas
          className="editTanentOffcanvas"
          show={showModal}
          onHide={() => setShowModal(false)}
          placement={"end"}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Edit Tenant</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {userData && (
              <EditTenentForm
                setShowSuccessAlert={setShowSuccessAlert}
                id={userData?.id}
                setShowModal={setShowModal}
                setAlertMessage={setAlertMessage}
              />
            )}
          </Offcanvas.Body>
        </Offcanvas>

        <div className="d-flex justify-content-center">
          <UploadDocumentModal
            showDocumentModal={showDocumentModal}
            handleClose={handleClose}
            setShowDocumentModal={setShowDocumentModal}
            id={Number(id)}
            setShowSuccessAlert={setShowSuccessAlert}
            setAlertMessage={setAlertMessage}
          />
        </div>

        {showSuccess && (
          <SuccessAlert
            message={"Payment Added Successfully"}
            setShowSuccessAlert={setShowSuccess}
          />
        )}
       </div>
      </div>
    </>
    // )}} />
  );
};

export default NewSingleTenantView;
