import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { generateMenuRoutes, USERS, USER_ROLES } from "../constants/constants";
import dashboardIcon from "../assets/dashboard.png";
import selectedDashboardIcon from "../assets/selectedDashboard.svg";
import MenuItemCustom from "./MenuItemCustom";
import { GetImages } from "../utils/GetImages";
import { setHiddenSideBar, setOpenSideBar } from "../redux/actions/commanAction";
import "./CustomMenuStyles.css";

type Values = {
  noOfUnits: string;
  noOfTenants: string;
  noOfProperties: string;
  noOfServiceRequests: string;
  noOfUsers: string;
  noOfContractors: string;
};

const CustomMenu = ({ isOpen, setIsOpen, isHidden, setIsHidden, size }) => {
  const dispatch: any = useDispatch();
  const [values, setValues] = useState<Values>({
    noOfUnits: "",
    noOfTenants: "",
    noOfProperties: "",
    noOfServiceRequests: "",
    noOfUsers: "",
    noOfContractors: "",
  });
  const menuRoutes = generateMenuRoutes(values, 1);

  return (
    <div>
      {USER_ROLES[1] === USERS.USER ? (
        <div className={isOpen ? "normalClass" : "collapsedBarClass"}>
          <MenuItemCustom
            primaryText="Dashboard"
            to="/"
            key={"newMenu-1"}
            className={
              location.pathname === "/"
                ? "menuItemLinkClassSelected"
                : "menuItemLinkClass"
            }
            leftIcon={
              location.pathname === "/" ? selectedDashboardIcon : dashboardIcon
            }
            alt="Dashboard Icon"
          />
        </div>
      ) : null}

      {menuRoutes?.map((value, index) => {
        const isSelected = location.pathname.includes(value.path);
        return (
          <div
            key={index}
            className={isOpen ? "normalClass" : "collapsedBarClass"}
          >
            <MenuItemCustom
              to={value.path}
              primaryText={value.primaryText}
              leftIcon={!isSelected ? value.leftIcon : value.selectedIcon}
              className={
                isSelected ? "menuItemLinkClassSelected" : "menuItemLinkClass"
              }
              alt={value.primaryText}
            />
          </div>
        );
      })}

      <img
        onClick={() => {
          if (size < 400) {
            setIsHidden(true);
            setIsOpen(!isOpen);
            dispatch(setOpenSideBar(!isOpen))
            dispatch(setHiddenSideBar(true))
          } else {
            setIsOpen(!isOpen);
            dispatch(setOpenSideBar(!isOpen))
          }
        }}
        className="collapseAbleIconStyle"
        src={isOpen ? GetImages.collapseAbleIcon : GetImages.alreadyCollapsed}
      />
    </div>
  );
};

export default CustomMenu;
