import Form from "react-bootstrap/Form";
import "./tenantStyles.css";
import { useState } from "react";

function NewSelectAuto({
  data,
  disabled,
  setDisabled,
  selected,
  setSelected,
  title,
  type,
  setResetDependencies = null,
  error,
  setError,
  fromContractor = false,
  label = "hello",
  width = "298px",
}) {
  
  const [selectedOption, setSelectedOption] = useState(null);
  const handleChange = (e) => {
    setError(false);
    if (setResetDependencies) {
      setResetDependencies([]);
    }
    setSelected(Number(e.target.value));
    setSelectedOption(e.target.options[e.target.selectedIndex].text);
  };

  return (
    <div
      style={{
        width: width,
      }}
      title={disabled ? title : ""}
      className={
        disabled
          ? " customEditTenantInputContainerStyles disabled"
          : "customEditTenantInputContainerStyles"
      }
    >
      {fromContractor && (
        <div>
          <p className="formLabel">{label}</p>
        </div>
      )}

      <Form.Select
        className={disabled ? "disabled FormSelectCustom" : "FormSelectCustom"}
        onChange={handleChange}
        aria-label="Default select example"
        style={{ color: selected === null ? "#9ca3af" : "#111928" }}
        value={selected || ""}
      >
        <option value="" disabled>{`Select ${type}`}</option>

        {data && data.length > 0 ? (
          data.map((item) => (
            <option key={item?.id} value={item?.id}>
              {type === "property"
                ? item?.name
                : type === "unit"
                ? item?.unitNumber
                : item?.name}
            </option>
          ))
        ) : (
          <option disabled>No {type} listed</option>
        )}
      </Form.Select>
    </div>
  );
}

export default NewSelectAuto;
