import React, { useEffect, useRef, useState } from "react";
import {
  CommunicationService,
  MessageEntityTypesEnum,
  MessageVM,
  TenantService,
} from "@propertelligent/client-api";
import AddTraspButton from "../../firstTimeUserExperience/commonCom/AddTraspButton";
import NameAvatarComponent from "../../tenants/NameAvatarComponent";
import { UserLoginData } from "../../common/charts/Enums";
import DateHelper from "../../../utils/DateHelper";
import User from "../../../assets/userAvatar.png";

const ChatBox = ({ entityId, entityTypeId, chatname, fetchData ,setEntityId,setEntityTypeId,setChatName}) => {
 
  const [chatdata, setChatdata] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [listdata, setList] = useState([]);
  const [tenantList,settenantList] =useState([])
  const [selectedItem, setSelectedItem] = useState(null);

  const scrollDown =useRef(null)
  
  const [messageData, setmessageData] = useState<MessageVM>({
    entityTypeId: entityTypeId,
    entityId: entityId,
  });
  

  const fetchList =()=>{
    TenantService.getApiTenantOrganizationlookup(parseInt(localStorage.getItem(UserLoginData.organization_id)), undefined,  undefined ,undefined ,`User.FirstName.ToLower().Contains("${inputValue.toLowerCase()}")or User.LastName.ToLower().Contains("${inputValue.toLowerCase()}")`)
    .then((res)=>settenantList(res.success))
    .catch((error) => {});
  }

  useEffect(() => {
    fetchList()
  }, [ inputValue]);
  
  useEffect(() => {
    fetchData();
    if(inputValue==""){
      setSelectedItem(null);
    }
  }, [tenantList?.length,inputValue]);
  

  const fetchMessages = () => {
    if (entityTypeId == MessageEntityTypesEnum.Tenant) {
      CommunicationService.getApiMessageTenantlist(entityId)
        .then((res) => setChatdata(res.success))
        .catch((error) => {});
    } else if (entityTypeId == MessageEntityTypesEnum.Contractor) {
      CommunicationService.getApiMessageContractorlist(entityId)
        .then((res) => setChatdata(res.success))
        .catch((error) => {});
    }
  };

  useEffect(() => {
    setmessageData({
      ...messageData,
      messageData: "",
      entityTypeId: entityTypeId,
      entityId: entityId,
    });
    scrollDown?.current?.scrollIntoView()
    fetchMessages();
  }, [
    entityId,
    entityTypeId,
    chatname,
    chatdata?.length,
    messageData?.entityId,
  ]);
  const name = chatname?.split(" ");

  const sendMessage = () => {
    CommunicationService.postApiMessage(messageData)
      .then((res) => {
        if (res.success) {
          setmessageData({
            ...messageData,
            messageData: "",
            entityTypeId: entityTypeId,
            entityId: entityId,
          });
          fetchMessages();
          fetchData();
        }
      })
      .catch((error) => {});
  };

  const resetSearch = () => {
    setList(tenantList?.map((item) => item.firstName));
  };
  const checkMatch = () => {
    if (inputValue !== "") {
      return tenantList?.map((item, index) => {
        if (
          item?.name.toLowerCase().includes(inputValue.toLowerCase()) &&
          selectedItem?.name !== item.name
        ) {
          return (
            <li
              key={index}
              className={`lists showMe`}
              onClick={() => complete(item)}
            >
              {item.name}
            </li>
          );
        } else {
          return null;
        }
      });
    }
  };
  const complete = (value) => {
    setEntityId(value.id) 
    setInputValue(value.name);
    setChatName(value.name)
    setInputValue("")
    setSelectedItem(value);
  };

   return (
     <>
       {entityId == null ? (
         <div className="chatboxContainer">
           <div className="chatboxtop">
             <div className="selectTanent">
               <div className="box-container">
                 <input
                   type="text"
                   id="userName"
                   placeholder="Select tenant"
                   value={inputValue}
                   onChange={(e) => setInputValue(e.target.value)}
                   onFocus={resetSearch}
                 />
               </div>
             </div>
             {selectedItem?.name || inputValue == "" ? (
               ""
             ) : (
               <ul className="selectedChat">{checkMatch()}</ul>
             )}
           </div>
           <div className="chatboxbottom">
             <input
               type="text"
               name="messageData"
               value={messageData.messageData}
               onChange={(e) =>
                 setmessageData({ ...messageData, messageData: e.target.value })
               }
               placeholder="Type your message here...."
               className="serchInput"
             />
             <AddTraspButton
               bname="Send"
               iconPosition={undefined}
               imageValue={undefined}
               onClick={undefined}
               border={false}
             />
           </div>
         </div>
       ) : (
         <div className="chatboxContainer">
           <div className="chatDetails">
             <NameAvatarComponent
               firstName={name[0]}
               lastName={name[1]}
               heightWidth={40}
             />
             <p className="FW600_LH24_black_text">{chatname}</p>
           </div>
           <div className="messageSection">
             {chatdata.map((item) => (
               <>
                 <div
                   key={item.id}
                   className={`${
                     item.createdById ===
                     parseInt(localStorage.getItem(UserLoginData.user_id))
                       ? "outgoingMessage"
                       : "incommingMessage"
                   }`}
                 >
                   <div className="chatDp">
                     <img
                       src={User}
                       className="w-100 h-100"
                       alt="User Avatar"
                     />
                   </div>
                   <div className="chatMessage ">
                     <p className="FW400_LH16_FS12_black receiver">
                       {item?.messageData}
                     </p>
                     <p className="FW400_LH14_small_text_black ">
                       {DateHelper.convertUTCtoDateTime(item?.created, true)}
                     </p>
                   </div>
                 </div>
                 <div ref={scrollDown} />
               </>
             ))}
           </div>
           <div className="chatboxbottom">
             <input
               type="text"
               name="messageData"
               value={messageData.messageData}
               onChange={(e) =>
                 setmessageData({ ...messageData, messageData: e.target.value })
               }
               placeholder="Type your message here...."
               className="serchInput"
             />
             <AddTraspButton
               bname="Send"
               iconPosition={undefined}
               imageValue={undefined}
               onClick={() => sendMessage()}
               border={false}
             />
           </div>
         </div>
       )}
     </>
   );
};

export default ChatBox;
