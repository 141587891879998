import React, { useEffect, useState } from "react";

import { AnalyticsService } from "@propertelligent/client-api";
import { UserLoginData } from "../components/common/charts/Enums";
import { GetImages } from "../utils/GetImages";
const TopSection = () => {
  const organization_id = Number(
    localStorage.getItem(UserLoginData.organization_id)
  );
  const [analyticsData, setAnalyticsData] = useState<any>({});

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = () => {
    AnalyticsService.getApiAnalyticsDashboard(organization_id)
      .then((res) => setAnalyticsData(res?.success))
      .catch((err) => {});
  };

  return (
    <div className="topSectionContainer d-flex flex-wrap">
      <div className="topSectionComponent">
        <div className="upperComponent d-flex align-items-center">
          <img width={50} height={50} src={GetImages.homeAvatar} />
          <div className="rightSideTextComponent">
            <p className="openActionAmount1">$ 
              {" "}
              {analyticsData?.formatted?.PortfolioValue
                ? analyticsData?.formatted?.PortfolioValue
                : "0"}{" "}
            </p>
            <p className="openActionDate1">Total Assets Value</p>
          </div>
        </div>
        <div className="lowerComponent d-flex align-items-center">
          <div className="statContainer">
            <p className="openActionTitle1">
              {analyticsData.numberOfProperties
                ? analyticsData.numberOfProperties
                : "0"}
            </p>
            <p className="openActionDate1">Properties</p>
          </div>
          <div className="statContainer">
            <p className="openActionTitle1">
              {analyticsData.numberOfUnits ? analyticsData.numberOfUnits : "0"}
            </p>
            <p className="openActionDate1">Total Units</p>
          </div>
          <div className="statContainer">
            <p className="openActionTitle1">
              {analyticsData.numberOfVacantUnits
                ? analyticsData.numberOfVacantUnits
                : "0"}
            </p>
            <p className="openActionDate1">Vacant Units</p>
          </div>
        </div>
      </div>
      <div className="topSectionComponent">
        <div className="upperComponent d-flex align-items-center">
          <img width={50} height={50} src={GetImages.totalTenantAvatar} />
          <div className="rightSideTextComponent">
            <p className="openActionAmount1">
              {analyticsData.numberOfTenants
                ? analyticsData.numberOfTenants
                : "0"}
            </p>
            <p className="openActionDate1">Total Tenants</p>
          </div>
        </div>
        <div className="lowerComponent d-flex align-items-center">
          <div className="statContainer">
            <p className="openActionTitle1">
              {analyticsData?.tenantsLeaseExpiring
                ? analyticsData?.tenantsLeaseExpiring
                : "0"}
            </p>
            <p className="openActionDate1">Lease Expiring</p>
          </div>
          <div className="statContainer">
            <p className="openActionTitle1">
              {analyticsData?.tenantsMovingOut
                ? analyticsData?.tenantsMovingOut
                : "0"}
            </p>
            <p className="openActionDate1">Moving Out</p>
          </div>
          <div className="statContainer">
            <p className="openActionTitle1">
              {analyticsData?.tenantsMovingIn
                ? analyticsData?.tenantsMovingIn
                : "0"}
            </p>
            <p className="openActionDate1">Prospect</p>
          </div>
        </div>
      </div>
      <div className="topSectionComponent">
        <div className="upperComponent d-flex align-items-center">
          <img width={50} height={50} src={GetImages.serviceRequestAvatar} />
          <div className="rightSideTextComponent">
            <p className="openActionAmount1">
              {analyticsData?.openServiceRequests
                ? analyticsData?.openServiceRequests
                : "0"}{" "}
            </p>
            <p className="openActionDate1">Open Service Requests</p>
          </div>
        </div>
        <div className="gap-5 lowerComponent d-flex align-items-center justify-content-start">
          <div style={{ width: "40%" }} className="statContainer">
            <p className="openActionTitle1">
              $
              {analyticsData?.serviceRequestsEstimatedExpenses
                ? analyticsData?.serviceRequestsEstimatedExpenses?.toLocaleString(
                    "en-US"
                  )
                : "0"}
            </p>
            <p className="openActionDate1">Estimated Expense</p>
          </div>
          <div style={{ width: "40%" }} className="statContainer">
            <p className="openActionTitle1">
              $
              {analyticsData?.serviceRequestsPaymentPending
                ? analyticsData?.serviceRequestsPaymentPending?.toLocaleString(
                    "en-US"
                  )
                : "0"}
            </p>
            <p className="openActionDate1">Payment Pending</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
