import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  DocumentEntityTypesEnum,
  DocumentService,
  DocumentTypesEnum,
  PropertyService,
  PropertyVM,
} from "@propertelligent/client-api";
import AddressAutocomplete from "./commonCom/AddressAutocomplete";
import Progressbar from "./commonCom/Progressbar";
import FormFooter from "./commonCom/FormFooter";
import SuccessAlert from "../tenants/SuccessAlert";
import { GetImages } from "../../utils/GetImages";
import Uploader from "../common/CustomUploader/Uploader";
import Delete from "../../assets/Delete.png";
import Replace from "../../assets/Replace.png";

const SelectProperty = ({ ptyTypeId, setPtyTypeId, navigateto }) => {
  const [propertyValue, setPropertyvalue] = useState<PropertyVM>();
  const [fileName, setFileName] = useState("");
  const [progress, setProgress] = useState(0);
  const [mainImage, setMainImage] = useState(null);
  const [nextvalue, setNextvalue] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const navigate = useNavigate();

  const handleDelete = () => {
    setMainImage(null);
    setFileName(null);
    setProgress(0);
  };

  const data = [
    {
      id: 1,
      maintext: "Single Family",
      subText: "Single Family Residential Unit",
      icon: GetImages.SingleFamily,
    },
    {
      id: 2,
      maintext: "Multi Family",
      subText: "Multi Family Residential Units",
      icon: GetImages.MultiFamily,
    },
  ];

  const handleSubmit = async (e: any) => {
    if (!propertyValue?.address?.streetAddress) {
      setErrorMessage(true);
    } else {
      const response = await PropertyService.postApiProperty(propertyValue);
      if (response?.success) {
        if (mainImage) {
          const imgData = {
            EntityTypeId: DocumentEntityTypesEnum.Property,
            EntityId: Number(response.success.id),
            DocumentTypeId: DocumentTypesEnum.BeforeImage,
            IsMain: true,
            Document: mainImage,
            FileName: fileName,
          };

          try {
            await DocumentService.postApiDocument(imgData);
            (true);
            setShowSuccessAlert(true);
            setTimeout(() => {
              navigate(navigateto, { state: { id: response.success.id } });
            }, 3000);
          } catch (imageUploadError) { }
        } else {
          setShowSuccessAlert(true);
          setTimeout(() => {
            navigate(navigateto, { state: { id: response.success.id } });
          }, 3000);
        }
      }
    }
  };

  return (
    <>
      <div className="AddProperty">
        <div className="progressSection">
          {ptyTypeId && nextvalue ? (
            <Progressbar stepname="Create Property" stepnum="2/3" />
          ) : (
            <Progressbar stepname="Create Property" stepnum="1/3" />
          )}
        </div>
        <div className="formWrapper ">
          <p className="FW600_LH28_main_text">
            {ptyTypeId && nextvalue
              ? "Add image and address of property"
              : "Choose the type of the property"}{" "}
          </p>
          {ptyTypeId && nextvalue ? (
            <>
              <div className="addressUploader">
                <div className="uploader">
                  <Uploader
                    name="Add property image"
                    setMainImage={setMainImage}
                    mainImage={mainImage}
                    setFileName={setFileName}
                    progress={progress}
                    setProgress={setProgress}
                    Size={{
                      size: "Icon120",
                      iconSize: "Icon32",
                      pSize: 56,
                      uploadimage: "iconstyle18",
                    }}
                  />
                  <div>
                    {mainImage ? (
                      <>
                        <div className="deletereplacemain">
                          <div
                            onClick={() => setMainImage(null)}
                            className="deletereplace"
                          >
                            <img src={Replace} className="iconstyle" />
                            <p className="replace">Replace</p>
                          </div>
                          <div>
                            <div className="dividerSmallDiv"></div>
                          </div>
                          <div onClick={handleDelete} className="deletereplace">
                            <img src={Delete} className="iconstyle" />
                            <p className="delete">Delete</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <p className="FW400_LH14_small_text_black">
                        Image format jpeg and png. Max image size 2 MB
                      </p>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-column gap-2">
                  <AddressAutocomplete
                    propertyValue={propertyValue}
                    setPropertyvalue={setPropertyvalue}
                  />
                  {errorMessage && !propertyValue.address && (
                    <p className="FW400_LH20_FS14_red">Address is required</p>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="selectPropertyMain ">
              {data.map((item) => {
                return (
                  <>
                    <div
                      key={item.id}
                      className="singleAnswer"
                      style={{
                        border:
                          ptyTypeId === item.id
                            ? "1px solid #1A56DB"
                            : "1px solid  #E5E7EB",
                      }}
                      onClick={() => {
                        setPropertyvalue({ propertyTypeId: item.id });
                        setPtyTypeId(item.id);
                      }}
                    >
                      <div className="answerImage">
                        <img src={item.icon} />
                      </div>
                      <div className="answerText">
                        <p className="FW500_LH20_sub_text">{item.maintext}</p>
                        <p className="FW400_LH16_small_text">{item.subText}</p>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          )}
        </div>

        <FormFooter
          handleSubmit={handleSubmit}
          buttonname={
            propertyValue?.propertyTypeId && nextvalue
              ? "Create Property"
              : "Next"
          }
          setNextvalue={setNextvalue}
          nextvalue={nextvalue}
          propertyValue={propertyValue}
          navigateto={undefined}
          showCancelButton={false}
          handleCancel={undefined}
          handleFinish={undefined}
        />
      </div>
      {showSuccessAlert && (
        <SuccessAlert
          message={"Property Added Successfully"}
          setShowSuccessAlert={setShowSuccessAlert}
        />
      )}
    </>
  );
};

export default SelectProperty;
