import { Route, Routes } from "react-router";
import { PropertyEdit } from "./edit";
import "./properties.css";
import "../../components/firstTimeUserExperience/commonCom/CommonStyle.css";
import "../../components/firstTimeUserExperience/mainDashboardStyles.css";
import { PropertyService} from "@propertelligent/client-api";
import SelectProperty from "../firstTimeUserExperience/AddProperty";
import { useEffect, useState } from "react";
import AddUnitTanent from "../firstTimeUserExperience/AddUnitTanent";
import PropertyDetails from "./propertyDetails";
import ContentTopBottom from "../common/listPageLayout/ContentTopBottom";
import TopFixedView from "./listView/topFixedView";
import MainScrollView from "./listView/mainScrollView";
import { infiniteScrollHook } from "../../hooks/infiniteScrollHook";

export const Properties = () => {
  const [ptyTypeId, setPtyTypeId] = useState();
  const [fulllyQualifiedFilter, SetFullyQualifiedFilter] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const { rows, loading, hasMore, rowCount, fetchData, currentPage} = infiniteScrollHook({
    httpFetchDataFn: PropertyService.getApiPropertyOrganizationlist,
    FullyQualifiedFilters: fulllyQualifiedFilter,
  });

  useEffect(() => {
    if(searchValue !== ""){
      SetFullyQualifiedFilter(`Address.StreetAddress.ToLower().Contains("${searchValue.toLowerCase()}")`);
    } else {
      SetFullyQualifiedFilter(null);      
    }
  }, [searchValue]);

  return (
    <Routes>
      <Route index element={        
        <ContentTopBottom
            FixedTopContent={ () => <TopFixedView rowCount={rowCount} setSearchValue={setSearchValue} searchValue={searchValue} />}
            FixedBottomContent={() => <MainScrollView rows={rows} loading={loading} hasMore={hasMore} fetchData={fetchData} currentPage={currentPage} />} />
      } />
      <Route
        path="/create"
        element={
          <SelectProperty
            ptyTypeId={ptyTypeId}
            setPtyTypeId={setPtyTypeId}
            navigateto={"/properties/addunittanent"}
          />
        }
      />
      <Route
        path="/addunittanent"
        element={<AddUnitTanent navigateto={"/properties"} />}
      />
      <Route path="/details/:id" element={<PropertyDetails />} />

      <Route path="/edit/:id" element={<PropertyEdit />} />
    </Routes>
  );
};
