import { Link } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import calendar_black from "../../assets/calendar_black.png";
import Chevren_down from "../../assets/Chevren_down.png";
import Dot_Ellipse_2107 from "../../assets/Dot_Ellipse_2107.png";
import attach_file_skyblue from "../../assets/attach_file_skyblue.png";
import RequestBreadcrumb from "../common/RequestBreadcrumb";
import ServiceIcon from "../common/serviceTypeImage";
// import { convertDate } from "../../constants/constants"
import DateHelper from "../../utils/DateHelper";
import StatusTag from "../common/StatusTag";

const EditServiceRequestHeader = ({ serviceReqInfo }) => {
  const getStatusColorStyle = (status) => {
    switch (status) {
      case "Completed":
        return { backgroundColor: "#DEF7EC", color: "#057A55", label: "green" };
      case "Payment Pending":
        return {
          backgroundColor: "#EDEBFE",
          color: "#7E3AF2",
          label: "purple",
        };
      case "In Progress":
        return {
          backgroundColor: "#FDF6B2",
          color: "#C58C07",
          label: "yellow",
        };
      case "New Request":
        return {
          backgroundColor: "#E5EDFF",
          color: "#5850EC",
          label: "lightBlue",
        };
      case "Open":
        return {
          backgroundColor: "#FDF6B2",
          color: "#C58C07",
          label: "yellow",
        };
      case "Verification Pending":
        return { backgroundColor: "#E1EFFE", color: "#C58C07", label: "blue" };
    }
  };

  return (
    <>
      {/* <Row style={{ height: "10vh" }}>
				<RequestBreadcrumb firstName={"ServiceRequest"} link={"/servicerequest"} />
			</Row> */}
      <div className="serviceCardConatiner">
        <div className="propertCardLeft">
          <div
            className="imgcommonstyle"
            style={{ borderRadius: "50%", overflow: "hidden" }}
          >
            <ServiceIcon serviceType={serviceReqInfo?.formatted?.ServiceType} />
          </div>

          <div className="propertyCardDisc">
            <div className="propertyCardDetails ">
              <div className="d-flex align-items-center ">
                <span className="p-main-text">
                  {serviceReqInfo?.formatted?.ServiceSubType}
                </span>
              </div>
              <div>
                <span className="p-sub-text font-weight-bold">
                  {serviceReqInfo?.formatted?.ServiceType}
                </span>
              </div>
            </div>
            <div className="propertyCardQuantity">
              <div className="d-flex align-items-center ">
                <img
                  src={calendar_black}
                  className="iconstyle "
                  style={{ marginRight: "4px" }}
                />
                <span className="overview-small-text ml-1 ">
                  Raised on :
                  {DateHelper.convertUTCtoDateTimeSimple(
                    serviceReqInfo?.formatted?.RequestDateDateOnly
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
        <Row className="w-50">
          <Col>
            <Row className="justify-content-end">
              <div className="propertCardRight">
                <StatusTag
                  color={
                    getStatusColorStyle(
                      serviceReqInfo?.formatted?.ServiceRequestStatus
                    )?.label
                  }
                  statusText={serviceReqInfo?.formatted?.ServiceRequestStatus}
                  isLong
                />
              </div>
            </Row>
            <Row>
              <div
                className="d-flex align-items-center justify-content-end"
                style={{ marginTop: "12px" }}
              >
                <img
                  src={attach_file_skyblue}
                  className="iconstyle "
                  style={{ marginRight: "4px" }}
                />
                <span
                  className="overview-small-text ml-1 "
                  style={{ color: "#1A56DB", fontSize: "12px" }}
                >
                  View attachements
                </span>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EditServiceRequestHeader;
